import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import Form from './Form';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/orgReducer';

export class OrganizationsCreate extends Component {

  componentWillUnmount() {
    this.props.clearpostOrg();
  }

  render() {

    const {
      orgId, 
      postOrg, 
      clearpostOrg,
      submitting, 
      success, 
      error
    } = this.props || {}

    const handleSubmit = (values, ...rest) => {

      const [props, formFunctions] = rest || []

      const payload = Object.assign({}, values, {orgId})
      clearpostOrg();
      postOrg(payload, null, null, ()=>{
        formFunctions.reset()
      });
    }
    return (
      <Layout route="organizations-create">
        <MetaTags>
          <title>Create New Organization | RogoFire Portal</title>
        </MetaTags>
        <Row>
          <Col md={12}>
            <div className="app-head">
              <div className="app-header">
                <h1>Create New Organization</h1>
              </div>
            </div>
          </Col>
          {success &&
            !submitting &&
            <Col md={8}>
              <div className="alert alert-success alert-dismissible">
                <strong>Success!</strong> The Organization was successfully added.
              </div>
            </Col>
          }
          {!submitting &&
            !!error &&
            <Col md={8}>
              <div class="alert alert-danger   alert-dismissible">
                <strong>Error:</strong> {(error || {}).message}
              </div>
            </Col>
          }
          <Col md={6}>
            <Form
              onSubmit={handleSubmit}
              submitting={submitting}            
            />
          </Col>
        </Row>
      </Layout>
    );
  };
};

const mapState = state => {
  const {
    inProgress: submitting,
    result,
    error
  } = state.postOrg.toJS();

  const {orgId} = state.organizations || {};

  const success = !!result;

  return {
    success,
    submitting,
    error,
    orgId
  }
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      postOrg: fetchDux.postOrg.createAction,
      clearpostOrg: fetchDux.postOrg.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(OrganizationsCreate);
