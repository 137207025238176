import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import Form from './FormBlockOrganization';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import fetchDux from '../../../state/fetch-dux';
import { actionCreators as orgActionCreators} from '../../../state/orgReducer';

import { Link } from 'react-router-dom';

export class BlocksEditOrganization extends Component {

  componentWillUnmount() {
    this.props.clearPutDeviceOrgId();
  }

  componentWillMount() {
    //get all users to add
    this.props.getAllOrgs();
  }

  render() {

    const {
      getDevicesByOrgId,
      match,
      devices,
      orgId,
      inProgress,
      putDeviceOrgId,
      clearPutDeviceOrgId,
      editInProgress,
      error,
      orgs
    } = this.props || {}

    const {
      params
    } =match || {};

    const{
      id:selectedId
    } = params || {};

    if(!devices && orgId && !inProgress){
      getDevicesByOrgId(orgId);
    }

    const currentDevice = (devices || []).find(({deviceId})=>(deviceId === selectedId))

    const{
      deviceId,
    } = currentDevice || {}

    const handleSubmit = ({orgId:selectedOrgId}) => {
      clearPutDeviceOrgId();
      putDeviceOrgId({ orgId, deviceId, selectedOrgId }, null, null, ()=>{
        getDevicesByOrgId(orgId, null, true);
        window.location=`/blocks`
      });
    }

    const filteredOrgs = (orgs || []).filter(({_id:currentOrgId})=> currentOrgId !== orgId)

    return (
      <Layout route="blocks-edit">
        <MetaTags>
          <title>Assign Device To Organization | RogoFire Portal</title>
        </MetaTags>
        <Row>
          <Col md={12}>
            <div className="app-head">
              <div className="app-header">
                <h1>Assign Device To Organization</h1>
              </div>
            </div>
            {!editInProgress &&
              error &&
              <Col md={6}>
                <div class="alert alert-danger  alert-dismissible">
                  <strong>Error:</strong> {(error || {}).message}
                </div>
              </Col>
            }
            {currentDevice &&
              <Col md={6}>
                <Form
                  orgs={filteredOrgs} 
                  currentDevice={currentDevice} 
                  onSubmit={handleSubmit}
                />
              </Col>
            }
          </Col>
          {!currentDevice &&
           !editInProgress &&
            <Col md={6}>
            <div className="alert alert-danger  alert-dismissible">
              <strong>Error:</strong> device could not be found to edit.
            </div>
            <Link className="btn btn-outline-secondary btn-sm" to={`/blocks/${deviceId}`}>
               Go Back to Device Detail
            </Link>
          </Col>
          }
        </Row>
      </Layout>
    );
  };
};

const mapState = state => {

  const {
    result:devices,
    inProgress
  } = state.getDevicesByOrgId.toJS();

  const {
    error,
    inProgress:editInProgress
  } = state.putDeviceOrgId.toJS();

  const {
    result:orgs,
    inProgress:getAllOrgsInProgress,
    error:getAllOrgsError
  } = state.getAllOrgs.toJS();

  const {orgId} = state.organizations || {};

  return {
    orgId,
    devices,
    inProgress,
    editInProgress,
    error,
    orgs
  }
};

const mapDispatch = dispatch => bindActionCreators(
  {
    ...orgActionCreators,
    getDevicesByOrgId: fetchDux.getDevicesByOrgId.createAction,
    putDeviceOrgId: fetchDux.putDeviceOrgId.createAction,
    clearPutDeviceOrgId: fetchDux.putDeviceOrgId.clearAction,
    getAllOrgs: fetchDux.getAllOrgs.createAction,
  }, dispatch);

export default connect(mapState, mapDispatch)(BlocksEditOrganization);
