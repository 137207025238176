import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import Form from './Form';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/orgReducer';

import { Link } from 'react-router-dom';


  export class OrganizationsEdit extends Component {

    componentWillUnmount() {
      this.props.clearPutOrg();
    }
  
    componentWillMount() {
      const {
        organizations
      } = this.props || {}
  
      if(!organizations){
        this.props.getAllOrgs();
      }
    }
  
    render() {
  
    const {
      putOrg, 
      clearPutOrg,
      submitting, 
      result, 
      error,
      match,
      organizations,
      getAllOrgs
    } = this.props || {}

    if(!organizations && !submitting){
      getAllOrgs();
    }

    const {
      params
    } =match || {};

    const{
      id:selectedOrgId
    } = params || {};

    const currentOrg = (organizations || []).find(({_id:id})=>(id === selectedOrgId))

    const {
      name,
      slug
    } = currentOrg || {};

    const initValues = {
      name,
      slug
    }

    const handleSubmit = (values, ...rest) => {
      const payload = Object.assign({}, values, {orgId:selectedOrgId})
      clearPutOrg();
      putOrg(payload, null, null, ()=>{
        getAllOrgs(null, null, true);
      });
    }

    return (
      <Layout route="organizations-edit">
        <MetaTags>
          <title>Edit Organization | RogoFire Portal</title>
        </MetaTags>
        <Row>
          <Col md={12}>
            <div className="app-head">
              <div className="app-header">
                <h1>Edit Organization</h1>
              </div>
            </div>
          </Col>
          {currentOrg &&
            <Col md={12}>
              {result &&
                !submitting &&
                <Col md={8}>
                  <div className="alert alert-success alert-dismissible">
                    <strong>Success!</strong> The Organization was successfully updated.
                  </div>
                </Col>
              }
              {!submitting &&
                error &&
                <Col md={8}>
                  <div class="alert alert-danger  alert-dismissible">
                    <strong>Error:</strong> {(error || {}).message}
                  </div>
                </Col>
              }
              <Col md={6}>
                <Form 
                  onSubmit={handleSubmit}
                  submitting={submitting}
                  initialValues={initValues}
                  enableReinitialize
                />
              </Col>
            </Col>
          }
          {!currentOrg &&
           !submitting &&
            <Col md={8}>
            <div class="alert alert-danger  alert-dismissible">
              <strong>Error:</strong> organization could not be found to edit.
            </div>
            <Link className="btn btn-outline-secondary btn-sm" to={`/organizations`}>
               Go Back to organizations
            </Link>
          </Col>
          }
        </Row>
      </Layout>
    );
  };
};

const mapState = state => {
  const {
    inProgress: putOrgSubmitting,
    result,
    error
  } = state.putOrg.toJS();

  const {
    result:organizations,
    inProgress: getOrgsSubmitting
  } = state.getAllOrgs.toJS();

  const submitting = getOrgsSubmitting || putOrgSubmitting;

  return {
    submitting,
    error,
    organizations,
    result
  }
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      putOrg: fetchDux.putOrg.createAction,
      clearPutOrg: fetchDux.putOrg.clearAction,
      getAllOrgs: fetchDux.getAllOrgs.createAction,
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(OrganizationsEdit);
