import React from 'react';
import { reduxForm, Field, Form } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '../../ui/Icon';

const required = value => value ? undefined : 'Required';

const FORM_NAME = 'device-add-form'

const BlockForm = props => {
  const { handleSubmit, submitting, isEdit } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        name="name"
        type="text"
        component={FormField.Input}
        label="Device Name"
        validate={[required]}
      />
      <Field
        name="imei"
        type="text"
        component={FormField.Input}
        label={`IMEI ${isEdit?'(Read-only)':''}`}
        validate={[required]}
        disabled={isEdit}
      />
      <Field
        name="serial"
        type="text"
        component={FormField.Input}
        label={`Serial Number ${isEdit?'(Read-only)':''}`}
        validate={[required]}
        disabled={isEdit}
      />
      <Field
        name="breadcrumbEnable"
        type="checkbox"
        component={FormField.InputToggle}
        label="Enable GPS Breadcrumbs"
      />
      {/* {isEdit &&
        <Field
          name="isActive"
          type="checkbox"
          component={FormField.InputToggle}
          label="Active"
        />
      } */}
      <div className="form-actions">
        <Button
          variant="outline-success"
          type="submit"
          size="sm"
          disabled={submitting}
        >
          <Icon name="check" fixedWidth /> Submit
        </Button>
        <Link className="btn btn-outline-secondary btn-sm" to={`/blocks`}>
          <Icon name="times" fixedWidth /> Cancel
        </Link>
      </div>
    </Form>
  );
};

export default reduxForm({
  form: FORM_NAME
})(BlockForm);
