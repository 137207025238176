import React from 'react';
import { reduxForm, Field, Form } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '../../ui/Icon';

const required = value => value ? undefined : 'Required';

const BlockOrganizationForm = props => {
  const { handleSubmit, submitting, currentDevice, orgs } = props;

  const{
    readableOrg,
    orgId
  } = currentDevice || {}

  const{
    name,
    slug,
  } = readableOrg || {}

  return (
    <Form onSubmit={handleSubmit}>
      {/* <hr/> */}
      <span >Current Assigned Organization</span>
      <ul className="form-group-results">
        <li>
          <div><strong>Org Name:</strong>
            <p>{name}</p>
          </div>
          <div><strong>Slug:</strong>
            <p>{slug}</p>
          </div>
          <div><strong>Org Id:</strong>
            <p>{orgId}</p>
          </div>
          {/* <Button variant="text" type="submit" size="sm">
            <Icon name="trash" fixedWidth />
          </Button> */}
        </li>
      </ul>
      <hr/>
      <Field
        name="orgId"
        type="text"
        component={FormField.SelectReact}
        label="Select New Organization"
        validate={[required]}
        options={(orgs || []).map((org)=>{
          const{
            name:label,
            _id:value
          } = org || {};
          return(
            {
              label,
              value
            }
          )
        })}
      />
      <br/>
      <div className="form-actions">
        <Button
          variant="outline-success"
          type="submit"
          size="sm"
          disabled={submitting}
        >
          <Icon name="check" fixedWidth /> Submit
        </Button>
        <Link className="btn btn-outline-secondary btn-sm" to={`/blocks`}>
          <Icon name="times" fixedWidth /> Cancel
        </Link>
      </div>
    </Form>
  );
};

export default reduxForm({
  form: 'block-organization'
})(BlockOrganizationForm);
