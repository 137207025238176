import React from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import Form from './FormBlocks';

export const OrganizationsEditBlocks = () => {
  return (
    <Layout route="organizations-edit">
      <MetaTags>
        <title>Assign Blocks To Organization | RogoFire Portal</title>
      </MetaTags>
      <Row>
        <Col md={12}>

          <div className="app-head">

            <div className="app-header">
              <h1>Assign Blocks To Organization</h1>
            </div>

          </div>

        </Col>
        <Col md={6}>

          <Form />

        </Col>
      </Row>
    </Layout>
  );
};

export default OrganizationsEditBlocks;
