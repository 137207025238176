import React, {useState, Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import Form from './FormSendMessage';
import qs from 'qs';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/orgReducer';

class MessagesSend extends Component {
  constructor(props) {
    super(props);
    const{
      location
    } = this.props || {}

    const {
      search
    } = location || {}

    const params = qs.parse(search, { ignoreQueryPrefix: true });
    const {device} = params || {}
    const deviceImei = parseInt(device);

    this.state = { selectedDevices:( device ? [deviceImei] : []), noSelectedDevices:false};
  }

  componentWillUnmount() {
    this.props.clearPostSendMessage();
  }

  componentWillMount() {

    const {
      orgId
    } = this.props || {};
    
    this.props.clearPostSendMessage();
    this.props.getDevicesByOrgId(orgId);
  }

  componentWillReceiveProps(nextProps) {
    const oldOrg = this.props.orgId;
    const newOrg = nextProps.orgId;

    if (oldOrg === newOrg) return;
    this.props.getDevicesByOrgId(newOrg);
  }

  render(){

    const{
      location,
      devices,
      orgId,
      clearPostSendMessage,
      postSendMessage,
      error,
      submitting,
      result
    } = this.props || {};

    const{
      selectedDevices,
      noSelectedDevices
    } = this.state || {};

    const selectDevice = (imeiSelected) =>{
      const isInSelected = selectedDevices.some((imei)=>imeiSelected === imei);
  
      if(isInSelected){
        this.setState({selectedDevices:selectedDevices.filter((imei)=> imei !== imeiSelected)});
        return
      }
      this.setState({selectedDevices:selectedDevices.concat([imeiSelected])})
    }

    const handleSubmit = (values, ...rest) => {
      const [props, formFunctions] = rest || []
      this.setState({noSelectedDevices:false})
      const payload = Object.assign({}, values, {orgId, selectedDevices})
      if(!selectedDevices.length > 0 ){
        this.setState({noSelectedDevices:true})
        return
      }
      clearPostSendMessage();
      postSendMessage(payload, null, null, ()=>{
        formFunctions.reset()
      });
    }

    const filterActiveDevices = (devices || []).filter(({isDeleted}) => !isDeleted);

    return (
      <Layout route="message-details">
        <MetaTags>
          <title>Send Message | RogoFire Portal</title>
        </MetaTags>
        <Row>
          <Col md={12}>
  
            <div className="app-head">
  
              <div className="app-header">
                <h1>Send Message</h1>
              </div>
  
            </div>
            {result &&
              !submitting &&
              <Col md={8}>
                <div className="alert alert-success alert-dismissible">
                  <strong>Success!</strong> The message was successfully sent.
                </div>
              </Col>
            }
            {!submitting &&
              (error || noSelectedDevices) &&
              <Col md={8}>
                <div class="alert alert-danger  alert-dismissible">
                  <strong>Error:</strong>  {noSelectedDevices?'Please select a device to send to.':((error || {}).message? (error || {}).message :'Failed to send message.')}
                </div>
              </Col>
            }
          </Col>
          <Col md={12}>
  
            <Form
            data={filterActiveDevices}
            selectDevice={selectDevice}
            selectedDevices={selectedDevices}
            onSubmit={handleSubmit}
            submitting={submitting}
            />
  
          </Col>
        </Row>
      </Layout>
    );
  };
}

const mapState = state => {
  const {
    result:devices,
    inProgress:getDeviceInProgress
  } = state.getDevicesByOrgId.toJS();

  const {
    result,
    inProgress:postMessageInProgress,
    error
  } = state.postSendMessage.toJS();

  const {orgId} = state.organizations || {};

  const submitting = getDeviceInProgress || postMessageInProgress;

  return {
    devices,
    orgId,
    submitting,
    error,
    result
  }
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getDevicesByOrgId: fetchDux.getDevicesByOrgId.createAction,
      postSendMessage: fetchDux.postSendMessage.createAction,
      clearPostSendMessage: fetchDux.postSendMessage.clearAction,
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(MessagesSend);
