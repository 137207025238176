import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import Form from './FormBlock';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/orgReducer';

import { Link } from 'react-router-dom';

export class BlocksEdit extends Component {

  componentWillUnmount() {
    this.props.clearPutDevice();
  }

  componentWillMount() {
    const {
      orgId, 
      devices
    } = this.props || {}

    if(!devices){
      this.props.getDevicesByOrgId(orgId);
    }
  }

  render() {

    const {
      orgId, 
      putDevice, 
      clearPutDevice,
      submitting, 
      result, 
      error,
      match,
      devices,
      getDevicesByOrgId
    } = this.props || {}

    if(!devices && orgId && !submitting){
      this.props.getDevicesByOrgId(orgId);
    }

    const {
      params
    } =match || {};

    const{
      id:selectedDeviceId
    } = params || {};

    const currentDevice = (devices || []).find((
      {
        deviceId
      }
    )=>{
      return (deviceId === selectedDeviceId)
    })

    const {
      deviceId,
      isActive
    } = currentDevice || {};

    // default to true if no value present
    const breadcrumbEnable = currentDevice && currentDevice.device && currentDevice.device.breadcrumbEnable !== undefined ? !!currentDevice.device.breadcrumbEnable : true;

    const{
      device:deviceResult,
      metaData
    } = currentDevice || {}

    const {
      imei,
      serial
    } = deviceResult || {}
    const {
      name
    } = metaData || {}

    const initValues = {
      imei,
      name,
      serial,
      isActive,
      breadcrumbEnable
    }

    const handleSubmit = (values, ...rest) => {
      const payload = Object.assign({}, values, {orgId, deviceId})
      clearPutDevice();
      putDevice(payload, null, null, ()=>{
        getDevicesByOrgId(orgId, null, true);
      });
    }

    return (
      <Layout route="blocks-edit">
        <MetaTags>
          <title>Edit Device | RogoFire Portal</title>
        </MetaTags>
        <Row>
          <Col md={12}>
            <div className="app-head">
              <div className="app-header">
                <h1>Edit Block</h1>
              </div>
            </div>
            {result &&
              !submitting &&
              <Col md={6}>
                <div className="alert alert-success alert-dismissible">
                  <strong>Success!</strong> The Device was successfully updated.
                </div>
              </Col>
            }
            {!submitting &&
              error &&
              <Col md={6}>
                <div class="alert alert-danger  alert-dismissible">
                  <strong>Error:</strong> {(error || {}).message}
                </div>
              </Col>
            }
          </Col>
          {currentDevice &&
          <Col md={12}>
            <Col md={6}>
              <Form 
                onSubmit={handleSubmit}
                submitting={submitting}
                initialValues={initValues}
                enableReinitialize
                isEdit
              />
            </Col>
          </Col>
          }
          {!currentDevice &&
           !submitting &&
            <Col md={6}>
            <div className="alert alert-danger  alert-dismissible">
              <strong>Error:</strong> device could not be found to edit.
            </div>
            <Link className="btn btn-outline-secondary btn-sm" to={`/blocks`}>
               Go Back to Devices
            </Link>
          </Col>
          }
        </Row>
      </Layout>
    );
  };
};

const mapState = state => {
  const {
    inProgress: putDeviceSubmitting,
    result,
    error
  } = state.putDevice.toJS();

  const {
    result:devices,
    inProgress: getDevicesSubmitting
  } = state.getDevicesByOrgId.toJS();

  const {orgId} = state.organizations || {};

  const submitting = getDevicesSubmitting || putDeviceSubmitting;

  return {
    submitting,
    error,
    orgId,
    devices,
    result
  }
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      putDevice: fetchDux.putDevice.createAction,
      clearPutDevice: fetchDux.putDevice.clearAction,
      getDevicesByOrgId: fetchDux.getDevicesByOrgId.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(BlocksEdit);
