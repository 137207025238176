import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Navbar,
  Nav,
  Button,
  Form,
  FormControl,
  NavDropdown,
  Dropdown
} from 'react-bootstrap';
import { actionCreators } from '../../state/modals-dux';
import { actionCreators as orgActionCreators} from '../../state/orgReducer';
import fetchDux from '../../state/fetch-dux';

import NavbarBrandLink from './NavbarBrandLink';

import LogoutModal from '../routes/login/LogoutModal';

import rogoLogo from '../../styles/images/Rogo-Logo-300x119.png'
import clientLogo from '../../styles/images/ArapahoeOEM.png'

const TopNav = ({ user, showModal, orgId, setOrg, userAccount, getUserAccount, inProgress }) => {

  const {
    orgNames:orgs
  } = userAccount || {};

  const{
    _id,
    email
  } = user || {};

  if(!userAccount && user && !inProgress){
    getUserAccount(_id);
  }

  const selectOrg = (event, eventKey)=>{
    const{
      localStorage
    } = window || {}
  
    const{
      name,
      id:orgId
    } = eventKey.target || {};
  
    localStorage.setItem('orgId', orgId);
    setOrg({orgId})
  }

  const defaultOrg = ((orgs || [])[0] || {})._id;

  if(!orgId && defaultOrg && user){
    localStorage.setItem('orgId', defaultOrg);
    setOrg({orgId:defaultOrg})
  }
  
  const dropDownTitle = (( orgs || [] ).find(({_id:itemOrgId})=> itemOrgId === (orgId || defaultOrg)) || {}).name || 'Organizations';

  const navLink = (email || '').includes('arapahoe') ? '/client-portal' : '/'
  const imageLink = (email || '').includes('arapahoe') ? `url(${clientLogo})` : `url(${rogoLogo})`;

  return(
    <Navbar expand="lg" fixed="top" style={process.env.REACT_APP_ENV === 'dev' ? {backgroundColor: '#e9acac'} : null}>
      <LogoutModal />
      <NavbarBrandLink to={navLink} imageLink={imageLink}>RogoFire</NavbarBrandLink>
      <Navbar.Toggle aria-controls="navbar-nav" />
      <Navbar.Collapse id="navbar-nav" className="justify-content-between">
        <Form inline>
          {/* <FormControl
            type="text"
            placeholder="Quick Search"
            className="mr-sm-2"
          /> */}
          {/*
            <Dropdown.Menu>
              <Dropdown.Item href="">Search result</Dropdown.Item>
            </Dropdown.Menu>
          */}
        </Form>
        <Nav>
          <NavDropdown title={dropDownTitle} >
            {(orgs || []).map((org)=>{
              const {
                name,
                _id:orgId
              } = org || {};
              return (
                <NavDropdown.Item
                key={orgId}
                id={orgId}
                name={name}
                onSelect={selectOrg}
              >
                {name}
              </NavDropdown.Item>
              )
            })}
          </NavDropdown>

          {userAccount &&
            <Navbar.Text className="user">
              {(userAccount || {}).firstName && (userAccount || {}).lastName
                ? `${(userAccount || {}).firstName} ${(userAccount || {}).lastName.charAt(0)}.`
                : (userAccount || {}).email}
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={() => showModal('LogoutModal')}
              >
                Logout
              </Button>
            </Navbar.Text>}
        </Nav>

      </Navbar.Collapse>
    </Navbar>
  )
};

const mapState = state => {
  const user = state.login.toJS().result;

  const {orgId} = state.organizations || {};
  const {
    result:userAccount,
    inProgress
  } = state.getUserAccount.toJS() || {}

  return { user, orgId, userAccount, inProgress };
};
const mapDispatch = dispatch => bindActionCreators({
  ...orgActionCreators,
  ...actionCreators,
  getUserAccount: fetchDux.getUserAccount.createAction,
  
}, dispatch);
export default connect(mapState, mapDispatch)(TopNav);
