import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import Form from './FormUsers';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';

class OrganizationsEditUsers extends Component {
  componentWillUnmount() {
    this.props.clearPutOrgUsers();
    this.props.clearDeleteOrgUsers();
  }

  componentWillMount() {
    //get all users to add
    this.props.getAllUsers();
  }

  render(){

    const {
      org,
      allUsers,
      putOrgUsers,
      clearPutOrgUsers,
      getOrgDetails,
      clearDeleteOrgUsers,
      deleteOrgUsers,
      submitting,
      result,
      error
    } = this.props || {};

    const{
      users:orgUsers,
      _id:id
    } = org || {};

    const handleSubmit = ({userIdToAdd}, ...rest) => {
      const [props, formFunctions] = rest || []
      clearPutOrgUsers();
      clearDeleteOrgUsers();
      putOrgUsers({ orgId:id, userIdToAdd }, null, null, ()=>{
        getOrgDetails(id, null, true);
        formFunctions.reset()
      });
    }

    const handleDelete = (userIdToRemove) => {
      clearPutOrgUsers();
      clearDeleteOrgUsers();
      deleteOrgUsers({ orgId:id, userIdToRemove }, null, null, ()=>{
        getOrgDetails(id, null, true);
      });
    }
    
    const filteredAllUsers = (allUsers || []).filter(({_id:id})=> !(orgUsers || []).some(({_id})=>_id === id))

    return (
      <Layout route="organizations-edit">
        <MetaTags>
          <title>Edit Organization Users | RogoFire Portal</title>
        </MetaTags>
        <Row>
          <Col md={12}>
            <div className="app-head">
              <div className="app-header">
                <h1>Edit Organization Users</h1>
              </div>
            </div>
          </Col>
          {result &&
              !submitting &&
              <Col md={8}>
                <div className="alert alert-success alert-dismissible">
                  <strong>Success!</strong> The organization was successfully updated.
                </div>
              </Col>
          }
          {!submitting &&
            error &&
            <Col md={8}>
              <div class="alert alert-danger  alert-dismissible">
                <strong>Error:</strong>  failed to update organization.
              </div>
            </Col>
          }
          <Col md={6}>
            <Form 
              onSubmit={handleSubmit}
              orgUsers={orgUsers}
              allUsers={filteredAllUsers}
              enableReinitialize
              handleDelete={handleDelete}
              submitting={submitting}
            />
          </Col>
        </Row>
      </Layout>
    );
  };
};

const mapState = state => {
  const {
    result:org,
    inProgress:getOrgDetailsProgress
  } = state.getOrgDetails.toJS();

  const {
    result:allUsers,
    inProgress:getAllUsersProgress
  } = state.getAllUsers.toJS();

  const {
    result:deleteSuccess,
    inProgress:deleteUserProgress,
    error:deleteUserError
  } = state.deleteOrgUsers.toJS();

  const {
    result:putUserSuccess,
    inProgress:putOrgUsersProgress,
    error:putUserError
  } = state.putOrgUsers.toJS();

  const submitting = getOrgDetailsProgress || getAllUsersProgress || deleteUserProgress || putOrgUsersProgress
  const result = putUserSuccess || deleteSuccess;
  const error = putUserError || deleteUserError;

  return {
    org,
    allUsers,
    submitting,
    result,
    error
  }
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getOrgDetails: fetchDux.getOrgDetails.createAction,
      clearGetOrgDetails: fetchDux.getOrgDetails.clearAction,
      getAllUsers: fetchDux.getAllUsers.createAction,
      putOrgUsers: fetchDux.putOrgUsers.createAction,
      clearPutOrgUsers: fetchDux.putOrgUsers.clearAction,
      deleteOrgUsers: fetchDux.deleteOrgUsers.createAction,
      clearDeleteOrgUsers: fetchDux.deleteOrgUsers.clearAction,
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(OrganizationsEditUsers);