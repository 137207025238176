import React from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../ui/Layout';
import Box from '../../ui/Box';
import Icon from '../../ui/Icon';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import ModalDeleteUser from './ModalDeleteUser';

import fetchDux from '../../../state/fetch-dux';
import { actionCreators as orgActionCreators} from '../../../state/orgReducer';

export const UsersDetails = ({ 
  showModal, 
  users, 
  orgId, 
  match, 
  inProgress, 
  getAllUsersByOrg,
  deleteUser,
  deleteInProgress,
  deleteError
}) => {

  const {
    params
  } =match || {};

  const{
    id:selectedId
  } = params || {};

  if(!users && orgId && !inProgress){
    getAllUsersByOrg(orgId);
  }

  const currentUser = (users || []).find(({_id})=>(_id === selectedId))

  const {
    _id:id,
    firstName,
    lastName,
    email,
    isAdmin,
    orgNames
  } = currentUser || {}

  const handleDelete = (userId)=>{
    deleteUser(userId, null, null, ()=>{
      window.location='/users/'
    })
  }

  return (
    <Layout route="users-details">
      <MetaTags>
        <title>User Details | RogoFire Portal</title>
      </MetaTags>
      <Row>
        <Col md={12}>

          <div className="app-head">

            <div className="app-header">

              <h1>User Details</h1>

              <Link className="btn-back" to="/users">
                <Icon name="arrow-left" fixedWidth /> Back
              </Link>

            </div>

            <div className="app-actions">

              <Link
                className="btn btn-outline-secondary btn-sm"
                to={`/users/${id}/edit`}
              >
                <Icon name="wrench" fixedWidth /> Edit
              </Link>

              <Link
                className="btn btn-outline-secondary btn-sm"
                to={`/users/${id}/edit/organizations`}
              >
                <Icon name="building" fixedWidth /> Organizations
              </Link>

              <Button
                variant="outline-secondary"
                size="sm"
                onClick={() => showModal('DeleteUserModal')}
              >
                <Icon name="trash" fixedWidth /> Delete
              </Button>

              <ModalDeleteUser
                currentUser={currentUser}
                submit={handleDelete}
                deleteInProgress={deleteInProgress}
                deleteError={deleteError}
              />

            </div>

          </div>

        </Col>
        <Col md={6}>

          <Box header="User Details">
            <div className="box-data">
              <strong>Name</strong>
              <div>{firstName} {lastName}</div>
            </div>
            <div className="box-data">
              <strong>Email</strong>
              <div>{email}</div>
            </div>
            {isAdmin &&
              <div className="box-data danger">
                <Icon name="user-cog" /> This user has admin privileges
              </div>}
          </Box>

        </Col>
        <Col md={6}>

          <Box header="Organizations">
            <div>
              <span>{((orgNames || []).map(({slug})=> slug) || []).join(', ')}</span>
            </div>
          </Box>

        </Col>
      </Row>
    </Layout>
  );
};

const mapState = state => {

  const {
    result:users,
    inProgress
  } = state.getAllUsersByOrg.toJS();

  const {
    inProgress:deleteInProgress,
    error:deleteError,
  } = state.deleteUser.toJS();

  const {orgId} = state.organizations || {};

  return {
    orgId,
    users,
    inProgress,
    deleteInProgress,
    deleteError
  }
};

const mapDispatch = dispatch => bindActionCreators(
  {
    ...orgActionCreators,
    ...actionCreators,
    getAllUsersByOrg: fetchDux.getAllUsersByOrg.createAction,
    deleteUser: fetchDux.deleteUser.createAction,
    clearDeleteUser: fetchDux.deleteUser.clearAction
  }, dispatch);
export default connect(mapState, mapDispatch)(UsersDetails);
