import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '../../ui/Icon';

const required = value => value ? undefined : 'Required';

const OrganizationBlocksForm = props => {
  const { handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="blocks"
        type="text"
        component={FormField.SelectReact}
        label="Search Availabe Devices"
        validate={[required]}
        options={[]}
      />
      <ul className="form-group-results">
        <li>
          <div>Item</div>
          <Button variant="text" type="submit" size="sm">
            <Icon name="trash" fixedWidth />
          </Button>
        </li>
        <li>
          <div>Item</div>
          <Button variant="text" type="submit" size="sm">
            <Icon name="trash" fixedWidth />
          </Button>
        </li>
      </ul>
      <div className="form-actions">
        <Button
          variant="outline-success"
          type="submit"
          size="sm"
          disabled={submitting}
        >
          <Icon name="check" fixedWidth /> Submit
        </Button>
        <Link
          className="btn btn-outline-secondary btn-sm"
          to={`/organizations`}
        >
          <Icon name="times" fixedWidth /> Cancel
        </Link>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'organization-blocks'
})(OrganizationBlocksForm);
