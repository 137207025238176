import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '../../ui/Icon';
import Box from '../../ui/Box';

const required = value => value ? undefined : 'Required';

const UserForm = props => {
  const { handleSubmit, submitting } = props;
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Field
              name="firstName"
              type="text"
              component={FormField.Input}
              label="First Name"
              validate={[required]}
            />
          </Col>
          <Col>
            <Field
              name="lastName"
              type="text"
              component={FormField.Input}
              label="Last Name"
              validate={[required]}
            />
          </Col>
        </Row>
        <Field
          name="email"
          type="email"
          component={FormField.Input}
          label="Email"
          validate={[required]}
        />
        <Field
          name="password"
          type="password"
          component={FormField.Input}
          label="Password"
        />
        <Field
          name="isAdmin"
          type="checkbox"
          component={FormField.InputToggle}
          label="Admin"
        />
        <div className="form-actions">
          <Button
            variant="outline-success"
            type="submit"
            size="sm"
            disabled={submitting}
          >
            <Icon name="check" fixedWidth /> Submit
          </Button>
          <Link className="btn btn-outline-secondary btn-sm" to={`/users`}>
            <Icon name="times" fixedWidth /> Cancel
          </Link>
        </div>
      </form>
    </Box>
  );
};

export default reduxForm({
  form: 'user'
})(UserForm);
