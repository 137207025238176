import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import Layout from '../../ui/Layout';
import Box from '../../ui/Box';
import Icon from '../../ui/Icon';
import MapComponent from '../../ui/MapComponent';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/orgReducer';

class Homepage extends Component {
  componentWillMount() {
  
    const {orgId} = this.props || {}
    this.props.getDevicesByOrgId(orgId);
  }

  componentWillReceiveProps(nextProps) {
    const oldOrg = this.props.orgId;
    const newOrg = nextProps.orgId;

    if (oldOrg === newOrg) return;
    this.props.getDevicesByOrgId(newOrg);
  }

  render() {
    const {
      devices,
      user,
      inProgress
    } = this.props || {};

    const{
      isAdmin
    } = user || {};

    const filteredDevices = (devices || []).filter(({isDeleted})=>!isDeleted)
    const hasActiveDevices = (filteredDevices || []).length > 0

    return (
      <Layout route="homepage">
        <MetaTags>
          <title>Dashboard | RogoFire Portal</title>
        </MetaTags>
        <Row>
          <Col md={12}>

            <div className="app-head">

              <div className="app-header">
                <h1>Dashboard</h1>
              </div>

            </div>

          </Col>
          <Col md={12}>
            {hasActiveDevices && 
              <Box className="box-blocks" header="Active Devices">
                {(filteredDevices || []).map(device => {

                  const{
                    lastKnown,
                    deviceId
                  } = device || {};

                  const{
                    device:deviceResult,
                    metaData
                  } = device || {}

                  const {
                    imei
                  } = deviceResult || {}
                  const {
                    name
                  } = metaData || {}

                  const{
                    typeReadable,
                    latitude,
                    longitude
                  } = lastKnown || {};

                  const fix = latitude && longitude;

                  return(
                    <div className="block">
                      {/* {isAdmin &&
                        <Link
                          to={`/messages/send?device=${imei}`}
                          className="float-right btn btn-sm btn-outline-secondary"
                        >
                          <Icon name="comment" fixedWidth /> Send Message
                        </Link>
                      } */}
                      <Link to={`/blocks/${deviceId}`}>
                        <strong>{name || '-'}  ({imei || '-'})</strong>
                        <small>{typeReadable || 'Unknown Type'} - {fix ? 'Location acquired' : 'Location Unknown' }</small>
                      </Link>
                    </div>
                  )
                }
                )}
              </Box>
            }
            {!hasActiveDevices &&
              !inProgress &&
              <Col md={12}>
                <div className="alert alert-warning alert-dismissible">
                  <Icon name="info" fixedWidth />&emsp;
                  <strong>No Active Devices:</strong> The selected organization does not have any active devices. Check devices tab for all devices.
                </div>
             </Col>
            }

            <div className="box-map">
              <MapComponent 
                devices={filteredDevices || []} 
                onlyOne ={(filteredDevices || []).length === 1}
              />
            </div>

          </Col>
        </Row>
      </Layout>
    )
  };
};

const mapState = state => {
  const {
    result:devices,
    inProgress
  } = state.getDevicesByOrgId.toJS();

  const {
    orgId
  } = state.organizations || {}

  const user = (state.login.toJS() || {}).result 

  return {
    devices,
    orgId,
    user,
    inProgress
  }
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getDevicesByOrgId: fetchDux.getDevicesByOrgId.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(Homepage);
