import {
  getJson,
  postJson,
  patchJson,
  putJson,
  del,
  delJson, put
} from './fetch';

import localForage from 'localforage';

const apiBase = process.env.REACT_APP_API_BASE;

export const postLogin = payload =>
  postJson({
    path: `${apiBase}/identity/login`,
    payload
  }).then(result => {
    localForage.setItem('identity', result);
    return result;
  });

export const validateLogin = () =>
  getJson({
    path: `${apiBase}/identity/validate`
  });

  // Start devices
  export const getDevicesByOrgId = (orgId) =>
  getJson({
    path: `${apiBase}/devices/${orgId}`
  });

  export const getDeviceDetail = (payload) =>
  getJson({
    path: `${apiBase}/devices/details/`,
    payload
  });

  export const postNewDevice = (payload) =>
  postJson({
    path: `${apiBase}/devices/`,
    payload
  });

  export const putDevice = (payload) =>
  putJson({
    path: `${apiBase}/devices/`,
    payload
  });

  export const putDeviceOrgId = (payload) =>
  putJson({
    path: `${apiBase}/devices/org`,
    payload
  });

  export const deleteDevice = (payload) =>
  delJson({
    path: `${apiBase}/devices/`,
    payload
  });
  // End devices

  //start messages
  export const getMessagesByOrgId = (orgId) =>
  getJson({
    path: `${apiBase}/messages/${orgId}`
  });
  export const postSendMessage = (payload) =>
  postJson({
    path: `${apiBase}/messages/`,
    payload
  });
  //end messages

  //start orgs
  export const getAllOrgs = () =>
  getJson({
    path: `${apiBase}/organizations/`
  });
  export const getOrgDetails = (orgId) =>
  getJson({
    path: `${apiBase}/organizations/${orgId}`
  });
  export const postOrg = (payload) =>
  postJson({
    path: `${apiBase}/organizations/`,
    payload
  });
  export const putOrg = (payload) =>
  putJson({
    path: `${apiBase}/organizations/`,
    payload
  });
  export const putOrgUsers = (payload) =>
  putJson({
    path: `${apiBase}/organizations/user/`,
    payload
  });
  export const deleteOrgUsers = (payload) =>
  delJson({
    path: `${apiBase}/organizations/user/`,
    payload
  });
  export const deleteOrg = (orgId) =>
  del({
    path: `${apiBase}/organizations/${orgId}`
  });
  //end orgs

  //start users
    export const getAllUsers = () =>
    getJson({
      path: `${apiBase}/users/`
    });
    export const getAllUsersByOrg = (orgId) =>
    getJson({
      path: `${apiBase}/users/${orgId}`
    });
    export const postNewUser = (payload) =>
    postJson({
      path: `${apiBase}/users/`,
      payload
    });
    export const putUser = (payload) =>
    putJson({
      path: `${apiBase}/users/`,
      payload
    });
    export const postUserOrg = (payload) =>
    postJson({
      path: `${apiBase}/users/organization`,
      payload
    });
    export const deleteUserOrg = (payload) =>
    delJson({
      path: `${apiBase}/users/organization`,
      payload
    });
    export const deleteUser = (userId) =>
    del({
      path: `${apiBase}/users/${userId}`
    });
  //end users

  //start account
    export const putAccount = (payload) =>
    putJson({
      path: `${apiBase}/account/`,
      payload
    }).then(result => {
      localForage.setItem('identity', result);
      return result;
    });

    export const putAccountPassword = (payload) =>
    putJson({
      path: `${apiBase}/account/password`,
      payload
    });

    export const getUserAccount = () =>
    getJson({
      path: `${apiBase}/account/`
    });
  //end account

//start token
export const postNewToken = (payload) =>
    postJson({
      path: `${apiBase}/tokens/create`,
      payload
    });

export const getAllTokens = () =>
    getJson({
      path: `${apiBase}/tokens/list`
    });

export const getToken = (tokenId) =>
    getJson({
      path: `${apiBase}/tokens/${tokenId}`
    });

export const deleteToken = (tokenId) =>
    del({ path: `${apiBase}/tokens/${tokenId}`});

export const postRegenToken = (tokenId) =>
    postJson({ path: `${apiBase}/tokens/${tokenId}/regen`, payload: {}});

export const postEditToken = ({tokenId, name, email}) =>
    postJson({ path: `${apiBase}/tokens/${tokenId}/edit`, payload: {name: name, email: email}});

export const putDeviceToToken = ({tokenId, imei}) =>
    put({path: `${apiBase}/tokens/${tokenId}/${imei}`});

export const deleteDeviceFromToken = ({tokenId, imei}) =>
    del({path: `${apiBase}/tokens/${tokenId}/${imei}`});

export const getTokenDevices = (tokenId) =>
    getJson({path: `${apiBase}/tokens/${tokenId}/list`});


//end token
