import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '../../ui/Icon';

const required = value => value ? undefined : 'Required';

const OrganizationUsersForm = props => {
  const { handleSubmit, submitting, orgUsers, allUsers, handleDelete } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="userIdToAdd"
        type="text"
        component={FormField.SelectReact}
        label="Search Users"
        validate={[required]}
        options={(allUsers || []).map(({_id:id, firstName, lastName })=>{
          return({
            label:`${firstName} ${lastName}`,
            value:id
          })
        })}
      />
      <ul className="form-group-results">
        {(orgUsers || []).map(({_id:id, firstName, lastName})=>{
          return(
            <li>
              <div>{firstName} {lastName}</div>
              <Button 
              variant="text" 
              size="sm" 
              onClick={()=>handleDelete(id)}
              disabled={submitting}
              >
                <Icon name="trash" fixedWidth />
              </Button>
              {submitting && <div>updating</div>}
            </li>
          )
        }) }
      </ul>
      <div className="form-actions">
        <Button
          variant="outline-success"
          type="submit"
          size="sm"
          disabled={submitting}
        >
          <Icon name="check" fixedWidth /> Submit
        </Button>
        <Link
          className="btn btn-outline-secondary btn-sm"
          to={`/organizations`}
        >
          <Icon name="times" fixedWidth /> Cancel
        </Link>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'organization-users'
})(OrganizationUsersForm);
