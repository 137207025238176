import React, {Component, useState} from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../ui/Layout';
import Box from '../../ui/Box';
import Icon from '../../ui/Icon';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import ModalDeleteBlock from './ModalDeleteBlock';
import MapComponent from '../../ui/MapComponent';
import Table from '../../ui/Table';
import moment from 'moment';

import fetchDux from '../../../state/fetch-dux';
import { actionCreators as orgActionCreators} from '../../../state/orgReducer';

const tempFixForTemperatureBug = (temperature) => {
  if(temperature > 21474836){
    return (((temperature*100) + 65535) - 4294967295)/100
  }

  return temperature;
}

const convertTemp = (temperature, getCelsius=false) => {
  if(!temperature) return null;
  //temporary fix for the below freezing temperature bug
  const tempFix = tempFixForTemperatureBug(temperature);

  if(getCelsius){
    const tempValue = (tempFix - 32)
    if(!tempValue) return (0).toFixed(2);

    return (tempValue / 1.8).toFixed(2);
  }

  return ((tempFix * 1.8) + 32).toFixed(2);
  
}

const getSymbol = (getCelsius=false) => {
  if(getCelsius){
    return <span>&#8451;</span>;
  }

  return (<span>&#8457;</span>);
   
 }

const columnsMessages = [
  {
    Header: 'Message ID',
    accessor: '_id',
    Cell: ({ value }) => <Link to={`/messages/${value}`}>{value}</Link>
  },
  {
    Header: 'Transmit Time',
    accessor: 'transmitTime',
    className: 'text-center',
    Cell: ({value}) => {       
      return (<span>{moment(value).format('MM/DD/YYYY HH:mm')}</span>)
    }
  },
  {
    Header: 'Direction',
    accessor: 'messageType',
    className: 'text-center'
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'text-center',
  },
  {
    Header: 'Message',
    accessor: 'decoded.str',
    Cell: ({original}) => { 
      const{
        decoded
      } = original || {}
      const{
        str
      } = decoded || {}      
      return (<span>{str}</span>)
    }
  },
  {
    Header: 'Actions',
    accessor: '',
    className: 'text-center',
    Cell: ({ value, original }) => (
      <div className="actions">
        <Link
          className="btn btn-outline-secondary btn-sm"
          to={`/messages/${original.id}`}
        >
          <Icon name="file" fixedWidth />
        </Link>
      </div>
    )
  }
];

const columnsLocations = [
  {
    Header: 'Asset Type',
    accessor: 'typeReadable',
    className: 'text-center',
  },
  {
    Header: 'Temperature',
    accessor: 'temperature',
    className: 'text-center',
    Cell: ({original}) => { 
      const{
        temperature
      } = original || {}   
      const getCelsius = false;
    return (<span>{convertTemp(temperature, getCelsius) || '-'} {getSymbol(getCelsius)}</span>)
    }
  },
  {
    Header: 'Humidity',
    accessor: 'humidity',
    className: 'text-center',
    Cell: ({original}) => { 
      const{
        humidity
      } = original || {}   
      return (<span>{ humidity ? (humidity).toFixed(2) : '-'}%</span>)
    }
  },
  {
    Header: 'Transmit Time',
    accessor: 'transmitTime',
    className: 'text-center',
    Cell: ({value}) => {       
      return (<span>{moment(value).format('MM/DD/YYYY HH:mm')}</span>)
    }
  },
  {
    Header: 'Location',
    accessor: 'latitude',
    className: 'text-center',
    Cell: ({original}) => { 
      const{
        latitude,
        longitude
      } = original || {}   
      return (<span>{latitude && longitude ? latitude + ', ' + longitude : 'Unknown'}</span>)
    }
  }
];

export class BlocksDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentWillUnmount() {
    this.props.clearGetDeviceDetail();
  }

  componentWillMount() {
    const {
      match,
      orgId
    } = this.props || {}
    const {
      params
    } =match || {};
    const{
      id:selectedId
    } = params || {};

    this.props.getDeviceDetail({selectedId, orgId});
    this.props.getDevicesByOrgId(orgId);
  }

  componentWillReceiveProps(nextProps) {
    const newOrg = nextProps.orgId;

    const {
      match,
      orgId:oldOrg
    } = this.props || {}
    const {
      params
    } =match || {};
    const{
      id:selectedId
    } = params || {};

    const currentDevice = (this.props.devices || []).find(({deviceId})=>(deviceId === selectedId));
    const history = nextProps.history;

    /* This is primarily to handle the user changing orgs to where the active device is not present.
     * This also occurs on refresh of a device page, which it should not. This is a work-around to
     * prevent the redirect when the current device is set later. This is covering up a problem where
     * the variables are not set on first render after a refresh!
     */
    if(!currentDevice && !this.props.inProgress) {
      if (!this.state.redirectTimeout) {
        // set timeout for redirect if we dont get the current device prop in time
        this.setState({
          redirectTimeout: setTimeout(function () {
            history.push('/blocks');
          }, 2500)
        })
      }
    } else {
      if (this.state.redirectTimeout) {
        // we have current device now (or its in progress), cancel the redirect timer
        clearTimeout(this.state.redirectTimeout);
        this.setState({redirectTimeout: undefined});
      }
    }

    if (oldOrg === newOrg) return;
    this.props.getDevicesByOrgId(newOrg);
    this.props.getDeviceDetail({selectedId, orgId:newOrg});
  }

  render() {

    const { 
      showModal, 
      devices, 
      deviceDetails,
      orgId,
      match,
      inProgress,
      deleteError,
      deleteInProgress,
      deleteDevice,
      clearDeleteDevice,
      history,
      user
    } = this.props || {};

    const{
      isAdmin
    }  = user || {};

    const {
      params
    } =match || {};

    const{
      id:selectedId
    } = params || {};

    if(!devices && orgId && !inProgress){
      this.props.getDevicesByOrgId(orgId);
    }

    const currentDevice = (devices || []).find(({deviceId})=>(deviceId === selectedId))
    
    const{
      readableOrg,
      isDeleted,
      lastKnown,
      _id:id,
      device,
      metaData,
      deviceId
    } = currentDevice || {};

    const {
      imei
    } = device || {}
    const {
      name
    } = metaData || {}

    const{
      name: orgName,
      slug: orgSlug
    } = readableOrg || {};

    const{
      latitude,
      longitude,
      nSat,
      typeReadable
    } = lastKnown || {};

    const {
      messages,
      locations,
    } = deviceDetails || {}

    const handleDelete = (deviceId)=>{
      deleteDevice({deviceId, orgId}, null, null, ()=>{
        window.location='/blocks'
      })
    }

    return (
      <Layout route="blocks-detail">
        <MetaTags>
          <title>Device Details | RogoFire Portal</title>
        </MetaTags>
        <Row>
          <Col md={12}>

            <div className="app-head">

              <div className="app-header">

                <h1>Device Details</h1>

                <Link className="btn-back" to="/blocks">
                  <Icon name="arrow-left" fixedWidth /> Back
                </Link>

              </div>
              {isAdmin &&
                <div className="app-actions">
                  {/* {!isDeleted &&
                    <Link
                      className="btn btn-outline-secondary btn-sm"
                      to={`/messages/send?device=${imei}`}
                    >
                      <Icon name="comment" fixedWidth /> Send Message
                    </Link>
                  } */}
                  {/* {!isDeleted &&
                    <Link
                      className="btn btn-outline-secondary btn-sm"
                      to={`/blocks/${deviceId}/edit`}
                    >
                      <Icon name="wrench" fixedWidth /> Edit
                    </Link>
                  } */}
                  <Link
                    className="btn btn-outline-secondary btn-sm"
                    to={`/blocks/${deviceId}/edit/organization`}
                  >
                    <Icon name="building" fixedWidth /> Organization
                  </Link>
                  {/* <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={() =>{
                      clearDeleteDevice();
                      showModal('DeleteBlockModal')}
                    }
                  >
                    <Icon name="hdd" fixedWidth /> Archive
                  </Button> */}
                  <ModalDeleteBlock
                    currentDevice={currentDevice}
                    deleteError={deleteError}
                    deleteInProgress={deleteInProgress}
                    submit={handleDelete}
                  />
                </div>
              }
              <div className="app-actions">
                {!isDeleted &&
                  <Link
                    className="btn btn-outline-secondary btn-sm"
                    to={`/blocks/${deviceId}/edit`}
                  >
                    <Icon name="wrench" fixedWidth /> Edit
                  </Link>
                }
              </div>
            </div>
          </Col>
          
          {!currentDevice &&
            !inProgress &&
              <Col md={12}>
                <Col md={6}>
                  <div className="alert alert-primary  alert-dismissible">
                    <strong>Info:</strong> device not found for selected organization, redirecting back to devices...
                  </div>
                  {/* <Link className="btn btn-outline-secondary btn-sm" to={`/blocks`}>
                    Go Back to Devices
                  </Link> */}
                </Col>
              </Col>
          }
          {currentDevice &&
            <Col md={12}>
              <Row>

                <Col md={6}>
                  <Box>
                    <div className="box-data">
                      <strong>IMEI</strong>
                      <div>{imei || '-'}</div>
                    </div>
                    <div className="box-data">
                      <strong>Name</strong>
                      <div>{name || '-'}</div>
                    </div>
                    <div className="box-data">
                      <strong>Organization</strong>
                      <div>{orgSlug || '-'}</div>
                    </div>
                    <div className="box-data">
                      <strong>Status</strong>
                      <div style={{color:(!isDeleted ? 'green' : 'red')}}>{(!isDeleted ? 'Active' : 'Archived') || 'Unknown'}</div>
                    </div>
                    <div className="box-data">
                      <strong>Type</strong>
                      <div>{typeReadable || 'Unknown'}</div>
                    </div>
                    <div className="box-data">
                      <strong>Location</strong>
                      <div>{latitude && longitude ? latitude + ', ' + longitude : 'Unknown'}</div>
                    </div>
                  </Box>
                </Col>
                <Col md={6}>
                  <div className="box-map">
                    <MapComponent 
                      devices={[currentDevice] || []} 
                      containerHeight={400} 
                      onlyOne
                      locationHistory={{[currentDevice.deviceId]:(messages || []).map(msg => {
                          return {
                            latitude: msg.decoded.json.latitude,
                            longitude: msg.decoded.json.longitude,
                            timestamp: msg.transmitTime
                          }
                        })}}
                      enableHistory={currentDevice.device.breadcrumbEnable}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="box-tabs">
                    <Tabs defaultActiveKey="messages">
                      {/* <Tab eventKey="active-incidents" title="Active Incidents" />
                      <Tab eventKey="past-incidents" title="Past Incidents" /> */}
                      <Tab eventKey="messages" title="Message History">
                        <Table
                          showExport
                          showColumnsSelector
                          columns={columnsMessages}
                          data={messages}
                          defaultPageSize={10}
                        />
                      </Tab>
                      <Tab eventKey="locations" title="Location History">
                        <Table
                          showExport
                          showColumnsSelector
                          columns={columnsLocations}
                          data={locations}
                          defaultPageSize={10}
                        />
                      </Tab>
                    </Tabs>
                  </div>
                </Col>
              </Row>
            </Col>
          }
        </Row>
      </Layout>
    );
  };
};

const mapState = state => {

  const {
    result:devices,
    inProgress
  } = state.getDevicesByOrgId.toJS();

  const {
    result:deviceDetails,
  } = state.getDeviceDetail.toJS();

  const {
    inProgress:deleteInProgress,
    error:deleteError,
  } = state.deleteDevice.toJS();

  const {orgId} = state.organizations || {};

  const user = (state.login.toJS() || {}).result 

  return {
    orgId,
    devices,
    deviceDetails,
    inProgress,
    deleteError,
    deleteInProgress,
    user
  }
};

const mapDispatch = dispatch => bindActionCreators(
  {
    ...orgActionCreators,
    ...actionCreators,
    getDevicesByOrgId: fetchDux.getDevicesByOrgId.createAction,
    getDeviceDetail: fetchDux.getDeviceDetail.createAction,
    clearGetDeviceDetail: fetchDux.getDeviceDetail.clearAction,
    deleteDevice: fetchDux.deleteDevice.createAction,
    clearDeleteDevice: fetchDux.deleteDevice.clearAction
  }, dispatch);
export default connect(mapState, mapDispatch)(BlocksDetails);
