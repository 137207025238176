import React from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import Icon from '../../ui/Icon';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Box from '../../ui/Box';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/orgReducer';

const data = {
  id: 3751,
  block: 49015420323751,
  blockName: 'RockBLOCK',
  direction: 'MO',
  status: null,
  details: '',
  message: '{ "latitude":"3951.62099", "NS":"N", "longitude":"10503.90686", "EW":"W", "numSats":"04", "type":"Asset" }',
  timestamp: 1589896587
};

export const MessagesDetails = ({ messages, match, orgId, getMessagesByOrgId, inProgress }) => {

  if(!messages && orgId && !inProgress){
    getMessagesByOrgId(orgId);
  }

  const {
    params
  } =match || {};
  const{
    id:selectedId
  } = params || {};

  const currentMessage = (messages || []).find((message)=>{
    const{
      _id:id
    } = message || {}

    return (selectedId === id);
  })

  const{
    _id:id,
    imei,
    deviceName,
    messageType,
    status,
    decoded,
    transmitTime
  } = currentMessage || {}

  const{
    count,
    total,
    str
  } = decoded || {}

  return (
    <Layout route="message-details">
      <MetaTags>
        <title>Message Details | RogoFire Portal</title>
      </MetaTags>
      <Row>
        <Col md={12}>

          <div className="app-head">

            <div className="app-header">
              <h1>Message Details</h1>
              <Link className="btn-back" to="/messages">
                <Icon name="arrow-left" fixedWidth /> Back
              </Link>
            </div>

          </div>

        </Col>
        <Col md={4}>

          <Box header="Details">
            <div className="box-data">
              <strong>Id</strong>
              <div>{id}</div>
            </div>
            <div className="box-data">
              <strong>Device IMEI</strong>
              <div>{imei || '-'}</div>
            </div>
            <div className="box-data">
              <strong>Device Name</strong>
              <div>{deviceName || '-'}</div>
            </div>
            <div className="box-data">
              <strong>Direction</strong>
              <div>{messageType || '-'}</div>
            </div>
            <div className="box-data">
              <strong>Status</strong>
              <div>{status || '-'}</div>
            </div>
            <div className="box-data">
              <strong>Details</strong>
              <div>Msg {count || '-'} of {total || '-'}</div>
            </div>
            <div className="box-data">
              <strong>Date Time</strong>
              <div>{moment(transmitTime).format('MM/DD/YYYY HH:mm')}</div>
            </div>
          </Box>

        </Col>
        <Col md={8}>

          <Box header="Message">
            <div style={{wordBreak:'break-all'}}>{str || '** Could not find message content **'}</div>
          </Box>

        </Col>
      </Row>
    </Layout>
  );
};


const mapState = state => {
  const {
    result:messages,
    inProgress
  } = state.getMessagesByOrgId.toJS();

  const {orgId} = state.organizations || {};

  return {
    messages,
    orgId,
    inProgress
  }
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getMessagesByOrgId: fetchDux.getMessagesByOrgId.createAction,
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(MessagesDetails);