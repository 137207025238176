import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import Form from './FormBlock';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/orgReducer';

export class BlocksCreate extends Component {

  componentWillUnmount() {
    this.props.clearPostNewDevice();
  }

  render() {

    const {
      orgId, 
      postNewDevice, 
      clearPostNewDevice,
      submitting, 
      success, 
      error
    } = this.props || {}

    const handleSubmit = (values, ...rest) => {

      const [props, formFunctions] = rest || []

      const payload = Object.assign({}, values, {orgId})
      clearPostNewDevice();
      postNewDevice(payload, null, null, ()=>{
        formFunctions.reset()
      });
    }

    return (
      <Layout route="blocks-create">
        <MetaTags>
          <title>Add New Block | RogoFire Portal</title>
        </MetaTags>
        <Row>
          <Col md={12}>
            <div className="app-head">
              <div className="app-header">
                <h1>Add New Device</h1>
              </div>
            </div>
            {success &&
              !submitting &&
              <Col md={6}>
                <div className="alert alert-success alert-dismissible">
                  <strong>Success!</strong> The Device was successfully added.
                </div>
              </Col>
            }
            {!submitting &&
              !!error &&
              <Col md={6}>
                <div class="alert alert-danger   alert-dismissible">
                  <strong>Error:</strong> {(error || {}).message}
                </div>
              </Col>
            }
          </Col>

          <Col md={6}>
            <Form
              onSubmit={handleSubmit}
              submitting={submitting}            
            />
          </Col>
        </Row>
      </Layout>
    )
  };
};

const mapState = state => {
  const {
    inProgress: submitting,
    result,
    error
  } = state.postNewDevice.toJS();

  const {orgId} = state.organizations || {};

  const success = result;

  return {
    success,
    submitting,
    error,
    orgId
  }
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      postNewDevice: fetchDux.postNewDevice.createAction,
      clearPostNewDevice: fetchDux.postNewDevice.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(BlocksCreate);