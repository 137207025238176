import React from 'react';
import Icon from './Icon';
import { connect } from 'react-redux';

import NavItemLink from './NavItemLink';

const SideNav = ({ user, showModal }) => {
  const{
    isAdmin
  } = user || {};
  return(
    <div className="side-navigation">
      <nav>
        <ul>
          <li>
            <NavItemLink to="/">
              <Icon name="clipboard" fixedWidth /> Dashboard
            </NavItemLink>
          </li>
          <li>
            <NavItemLink to="/blocks">
              <Icon name="cubes" fixedWidth /> Devices
            </NavItemLink>
          </li>
          <li>
            <NavItemLink to="/messages">
              <Icon name="comments" fixedWidth /> Messages
            </NavItemLink>
          </li>
          {isAdmin &&
            <li>
              <NavItemLink to="/organizations">
                <Icon name="building" fixedWidth /> Organizations
              </NavItemLink>
            </li>
          }
          {isAdmin &&
            <li>
              <NavItemLink to="/users">
                <Icon name="users" fixedWidth /> Users
              </NavItemLink>
            </li>
          }
          {isAdmin &&
            <li>
              <NavItemLink to="/tokens">
                <Icon name="code" fixedWidth /> API
              </NavItemLink>
            </li>
          }
          <li>
            <NavItemLink to="/settings">
              <Icon name="cogs" fixedWidth /> Settings
            </NavItemLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

const mapState = state => ({ user: state.login.toJS().result });
export default connect(mapState)(SideNav);