import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import Icon from '../../ui/Icon';

const required = value => value ? undefined : 'Required';

const SettingsProfileForm = props => {
  const { handleSubmit, closeModal, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Modal.Body>
        <Row>
          <Col>
            <Field
              name="firstName"
              type="text"
              component={FormField.Input}
              label="First Name"
              validate={[required]}
            />
          </Col>
          <Col>
            <Field
              name="lastName"
              type="text"
              component={FormField.Input}
              label="Last Name"
              validate={[required]}
            />
          </Col>
        </Row>
        <Field
          name="email"
          type="email"
          component={FormField.Input}
          label="Email"
          validate={[required]}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-success" size="sm" type="submit" disabled={submitting}>
          <Icon name="check" fixedWidth /> Save
        </Button>
        <Button variant="outline-secondary" size="sm" onClick={closeModal} disabled={submitting}>
          <Icon name="times" fixedWidth /> Cancel
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default reduxForm({
  form: 'settings-profile'
})(SettingsProfileForm);
