import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../state/fetch-dux';
import { actionCreators } from '../state/orgReducer';

import SecureRoute from './ui/SecureRoute';

import Homepage from './routes/homepage/Homepage';
import ClientPortal from './routes/clientPortal/ClientPortal';
import Blocks from './routes/blocks/Blocks';
import BlocksCreate from './routes/blocks/BlocksCreate';
import BlocksDetails from './routes/blocks/BlocksDetails';
import BlocksEdit from './routes/blocks/BlocksEdit';
import BlocksEditOrganization from './routes/blocks/BlocksEditOrganization';
import Messages from './routes/messages/Messages';
import MessagesDetails from './routes/messages/MessagesDetails';
import MessagesSend from './routes/messages/MessagesSend';
import Organizations from './routes/organizations/Organizations';
import OrganizationsCreate from './routes/organizations/OrganizationsCreate';
import OrganizationsDetails from './routes/organizations/OrganizationsDetails';
import OrganizationsEdit from './routes/organizations/OrganizationsEdit';
import OrganizationsEditUsers
  from './routes/organizations/OrganizationsEditUsers';
import OrganizationsEditBlocks
  from './routes/organizations/OrganizationsEditBlocks';
import Users from './routes/users/Users';
import UsersCreate from './routes/users/UsersCreate';
import UsersDetails from './routes/users/UsersDetails';
import UsersEdit from './routes/users/UsersEdit';
import UsersEditOrganizations from './routes/users/UsersEditOrganizations';
import Settings from './routes/settings/Settings';
import TokenList from './routes/api/TokenList'
import Login from './routes/login/Login';
import TokenCreate from "./routes/api/TokenCreate";
import TokenEdit from "./routes/api/TokenEdit";

class App extends Component {
  componentDidMount() {

    const{
      user,
      validateLogin,
      setOrg,
      getUserAccount,
      userAccount
    } = this.props || {}

    validateLogin(null, null, true);

    
    const {
      localStorage
    } = window || {};
    

    if(user) getUserAccount();
    
    const {
      orgNames:orgs
    } = userAccount || {}

    const defaultOrg = ((orgs || [])[0] || {})._id;
    
    //if no org set grab first in user array
    const orgId = localStorage.getItem('orgId') ? localStorage.getItem('orgId') : defaultOrg;

    setOrg({ orgId });
  }

  render() {
    return (
      <Router>
        <Switch>
          <SecureRoute path="/client-portal/:id" component={ClientPortal} exact />
          <SecureRoute path="/" component={Homepage} exact />
          <SecureRoute path="/blocks" component={Blocks} exact />
          <SecureRoute path="/blocks/add" component={BlocksCreate} exact isAdminRoute/>
          <SecureRoute path="/blocks/:id" component={BlocksDetails} exact />
          <SecureRoute path="/blocks/:id/edit" component={BlocksEdit} exact/>
          <SecureRoute
            path="/blocks/:id/edit/organization"
            component={BlocksEditOrganization}
            exact
            isAdminRoute
          />
          <SecureRoute path="/messages" component={Messages} exact />
          <SecureRoute path="/messages/send" component={MessagesSend} exact isAdminRoute/>
          <SecureRoute path="/messages/:id" component={MessagesDetails} exact />
          <SecureRoute path="/organizations" component={Organizations} exact isAdminRoute/>
          <SecureRoute
            path="/organizations/create"
            component={OrganizationsCreate}
            exact
            isAdminRoute
          />
          <SecureRoute
            path="/organizations/:id"
            component={OrganizationsDetails}
            exact
            isAdminRoute
          />
          <SecureRoute
            path="/organizations/:id/edit"
            component={OrganizationsEdit}
            exact
            isAdminRoute
          />
          <SecureRoute
            path="/organizations/:id/edit/users"
            component={OrganizationsEditUsers}
            exact
            isAdminRoute
          />
          <SecureRoute
            path="/organizations/:id/edit/blocks"
            component={OrganizationsEditBlocks}
            exact
            isAdminRoute
          />
          <SecureRoute path="/users" component={Users} exact isAdminRoute/>
          <SecureRoute path="/users/create" component={UsersCreate} exact isAdminRoute/>
          <SecureRoute path="/users/:id" component={UsersDetails} exact isAdminRoute/>
          <SecureRoute path="/users/:id/edit" component={UsersEdit} exact isAdminRoute/>
          <SecureRoute
            path="/users/:id/edit/organizations"
            component={UsersEditOrganizations}
            exact
            isAdminRoute
          />
          <SecureRoute path="/settings" component={Settings} exact />
          <SecureRoute path="/tokens" component={TokenList} exact isAdminRoute />
          <SecureRoute path="/tokens/create" component={TokenCreate} exact isAdminRoute/>
          <SecureRoute path="/tokens/:id" component={TokenEdit} exact isAdminRoute/>
          <Route path="/login" component={Login} />
          <Redirect to="/login" />
        </Switch>
      </Router>
    );
  }
}

const mapState = state => {

  const user = (state.login.toJS() || {}).result 

  const userAccount = (state.getUserAccount.toJS() || {}).result 

  return {
    user,
    userAccount
  }
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      validateLogin: fetchDux.validateLogin.createAction,
      getUserAccount: fetchDux.getUserAccount.createAction,
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(App);
