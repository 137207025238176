import React from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import Form from './Form';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/orgReducer';

import { Link } from 'react-router-dom';

export const UsersEdit = ({ 
  users, 
  orgId, 
  match, 
  inProgress,
  error,
  result,
  putUser,
  clearPutUser,
  getAllUsersByOrg,
  getUserAccount
}) => {

  const {
    params
  } =match || {};

  const{
    id:selectedId
  } = params || {};

  if(!users && orgId && !inProgress){
    getAllUsersByOrg(orgId);
  }

  const currentUser = (users || []).find(({_id})=>(_id === selectedId))

  const {
    _id:userId,
    firstName,
    lastName,
    email,
    isAdmin,
    orgNames
  } = currentUser || {}

  const initValues = {
    firstName,
    lastName,
    email,
    isAdmin
  }

  const handleSubmit = (values, ...rest) => {
    const payload = Object.assign({}, values, {orgId, userId})
    clearPutUser();
    putUser(payload, null, null, ()=>{
      getAllUsersByOrg(orgId, null, true);
      getUserAccount();
    });
  }

  return (
    <Layout route="users-edit">
      <MetaTags>
        <title>Edit User | RogoFire Portal</title>
      </MetaTags>
      <Row>
        <Col md={12}>
          <div className="app-head">
            <div className="app-header">
              <h1>Edit User</h1>
            </div>
          </div>
        </Col>
        {currentUser &&
          <Col md={12}>
            {result &&
              !inProgress &&
              <Col md={8}>
                <div className="alert alert-success alert-dismissible">
                  <strong>Success!</strong> The User was successfully updated.
                </div>
              </Col>
            }
            {!inProgress &&
              error &&
              <Col md={8}>
                <div class="alert alert-danger  alert-dismissible">
                  <strong>Error:</strong> {(error || {}).message}
                </div>
              </Col>
            }
            <Col md={6}>
            <Form 
                onSubmit={handleSubmit}
                inProgress={inProgress}
                initialValues={initValues}
                enableReinitialize
                isEdit
              />
            </Col>
          </Col>
        } 
        {!currentUser &&
           !inProgress &&
            <Col md={8}>
            <div class="alert alert-danger  alert-dismissible">
              <strong>Error:</strong> device could not be found to edit.
            </div>
            <Link className="btn btn-outline-secondary btn-sm" to={`/blocks`}>
               Go Back to Devices
            </Link>
          </Col>
          }
      </Row>
    </Layout>
  );
};

const mapState = state => {
  const {
    inProgress: putUserSubmitting,
    result,
    error
  } = state.putUser.toJS();

  const {
    result:users,
    inProgress: getUserInProgress
  } = state.getAllUsersByOrg.toJS();

  const {orgId} = state.organizations || {};

  const inProgress = putUserSubmitting || getUserInProgress;

  return {
    inProgress,
    error,
    orgId,
    users,
    result
  }
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      putUser: fetchDux.putUser.createAction,
      clearPutUser: fetchDux.putUser.clearAction,
      getAllUsersByOrg: fetchDux.getAllUsersByOrg.createAction,
      getUserAccount: fetchDux.getUserAccount.createAction,
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(UsersEdit);
