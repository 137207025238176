import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import Form from './FormOrganizations';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators as orgActionCreators} from '../../../state/orgReducer';

class UsersEditOrganizations extends Component {
  componentWillUnmount() {
    this.props.clearPostUserOrg();
    this.props.clearDeleteUserOrg();
  }

  componentWillMount() {
    const{
      users,
      orgs,
      submitting,
      orgId
    } = this.props || {};
    
    //get all users to add
    this.props.getAllOrgs();

    if(!submitting && !users && orgId){
      this.props.getAllUsersByOrg(orgId);
    }
  }

  render(){

    const {
      orgId,
      orgs,
      users,
      submitting,
      errorLoad,
      result,
      error,
      match,
      postUserOrg,
      clearPostUserOrg,
      getAllUsersByOrg,
      deleteUserOrg,
      clearDeleteUserOrg,
      getUserAccount
    } = this.props || {};

    const {
      params
    } =match || {};
  
    const{
      id:selectedId
    } = params || {};
    
    const currentUser = (users || []).find(({_id})=>(_id === selectedId))

    const{
      orgNames,
      _id:id
    } = currentUser || {};

    const handleSubmit = ({orgToAdd}, ...rest) => {
      const [props, formFunctions] = rest || []
      clearPostUserOrg();
      clearDeleteUserOrg();
      postUserOrg({ orgId:orgToAdd, userIdToAdd:id }, null, null, ()=>{
        getAllUsersByOrg(orgId, null, true);
        getUserAccount();
        formFunctions.reset()
      });
    }

    const handleDelete = (orgToDelete) => {
      clearPostUserOrg();
      clearDeleteUserOrg();
      deleteUserOrg({ orgId:orgToDelete, userIdToRemove:id }, null, null, ()=>{
        getAllUsersByOrg(orgId, null, true);
        getUserAccount();
      });
    }

    const filteredOrgs = (orgs || []).filter(({_id:id})=> !(orgNames || []).some(({_id})=>_id === id))

    return (
      <Layout route="users-edit">
        <MetaTags>
          <title>Edit User Organizations | RogoFire Portal</title>
        </MetaTags>
        <Row>
          <Col md={12}>
            <div className="app-head">
              <div className="app-header">
                <h1>Edit User Organizations</h1>
              </div>
            </div>
          </Col>
          {result &&
              !submitting &&
              <Col md={8}>
                <div className="alert alert-success alert-dismissible">
                  <strong>Success!</strong> The User was successfully updated.
                </div>
              </Col>
          }
          {!submitting &&
            error &&
            <Col md={8}>
              <div class="alert alert-danger  alert-dismissible">
                <strong>Error:</strong>  failed to update user.
              </div>
            </Col>
          }
          {!submitting &&
            errorLoad &&
            <Col md={8}>
              <div class="alert alert-danger  alert-dismissible">
                <strong>Error:</strong>  failed to load organizations or user.
              </div>
            </Col>
          }
          <Col md={6}>
            <Form 
              onSubmit={handleSubmit}
              orgs={filteredOrgs}
              currentUser={currentUser}
              enableReinitialize
              handleDelete={handleDelete}
              submitting={submitting}
            />
          </Col>
        </Row>
      </Layout>
    );
  };
};

const mapState = state => {
  const {
    result:users,
    inProgress:getUsersInProgress,
    error:getUsersError
  } = state.getAllUsersByOrg.toJS();

  const {
    result:orgs,
    inProgress:getAllOrgsInProgress,
    error:getAllOrgsError
  } = state.getAllOrgs.toJS();

  const {
    result:postUserOrgResult,
    inProgress:postUserOrgInProgress,
    error:postUserOrgError
  } = state.postUserOrg.toJS();

  const {
    result:deleteUserOrgOrgResult,
    inProgress:deleteUserOrgOrgInProgress,
    error:deleteUserOrgOrgError
  } = state.deleteUserOrg.toJS();

  const submitting = getAllOrgsInProgress || getUsersInProgress || postUserOrgInProgress || deleteUserOrgOrgInProgress
  const errorLoad = getAllOrgsError || getUsersError
  const error = postUserOrgError || deleteUserOrgOrgError
  const result = postUserOrgResult || deleteUserOrgOrgResult

  const {orgId} = state.organizations || {};

  return {
    orgId,
    orgs,
    users,
    submitting,
    errorLoad,
    result,
    error
  }
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...orgActionCreators,
      getAllOrgs: fetchDux.getAllOrgs.createAction,
      getAllUsersByOrg: fetchDux.getAllUsersByOrg.createAction,
      postUserOrg: fetchDux.postUserOrg.createAction,
      clearPostUserOrg: fetchDux.postUserOrg.clearAction,
      deleteUserOrg: fetchDux.deleteUserOrg.createAction,
      clearDeleteUserOrg: fetchDux.deleteUserOrg.clearAction,
      getUserAccount: fetchDux.getUserAccount.createAction,
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(UsersEditOrganizations);
