import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import Icon from '../../ui/Icon';

const ModalDeleteBlock = (
  { 
    openModal, 
    closeModal, 
    submit, 
    currentDevice,
    deleteError,
    deleteInProgress
  }) => {
    
  const {
    device,
    metaData,
    deviceId
  } = currentDevice || {}

  const{
    name
  } = metaData || {};

  const{
    imei
  } = device || {};

  return(
    <Modal show={openModal === 'DeleteBlockModal'} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Archive Device</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {deleteError &&
          !deleteInProgress &&
          <div className="alert alert-danger alert-dismissible">
            <strong>Error:</strong> The Device could not be archived.
          </div>
        }
        <h4>Are you sure you wish to archive this device?</h4>
        <br/>
        <p><strong>Name: </strong>&emsp;{name}</p>
        <p><strong>IMEI:</strong>&emsp;&emsp;{imei}</p>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" size="sm" onClick={()=>submit(deviceId)} disabled={deleteInProgress}>
          <Icon name="hdd" fixedWidth /> Archive
        </Button>
        <Button variant="outline-secondary" size="sm" onClick={closeModal} disabled={deleteInProgress}>
          <Icon name="times" fixedWidth /> Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalDeleteBlock);
