import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Table from '../../ui/Table';
import Icon from '../../ui/Icon';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/orgReducer';

const columns = [
  {
    Header: 'ID',
    accessor: '_id',
    Cell: ({ value }) => <Link to={`/messages/${value}`}>{value}</Link>
  },
  {
    Header: 'Transmit Time',
    accessor: 'transmitTime',
    className: 'text-center',
    Cell: ({ value }) => <span>{moment(value).format('MM/DD/YYYY HH:mm')}</span>
  },
  {
    Header: 'Device Name',
    accessor: 'deviceName',
    Cell: ({ value, original }) => {
      const{
        deviceName,
        imei,
        deviceId
      } = original || {}
      return(
        <Link to={`/blocks/${deviceId}`}>
          <div><strong>{deviceName}</strong></div>
          <small>{imei}</small>
        </Link>
      )
    }
  },
  {
    Header: 'Direction',
    accessor: 'messageType',
    className: 'text-center'
  },
  {
    Header: 'Status',
    accessor: 'status',
    className: 'text-center',
  },
  {
    Header: 'Details',
    accessor: 'decoded.details.count',
    Cell: ({ original }) => {
      const{
        decoded
      } = original || {}

      const{
        count,
        total
      } = decoded || {}
      return (
      <span>Msg {count} of {total}</span>
      )
    }
  },
  {
    Header: 'Message',
    accessor: 'decoded.str',
    Cell: ({original}) => { 
      const{
        decoded
      } = original || {}
      const{
        str
      } = decoded || {}      
      return (<span>{str}</span>)
    }
  },
  {
    Header: 'Actions',
    accessor: '',
    Cell: ({ original }) => {   
      const{
        _id:id
      } = original || {}
      return(
        <div className="actions">
          <Link
            className="btn btn-outline-secondary btn-sm"
            to={`/messages/${id}`}
          >
            <Icon name="file" fixedWidth />
          </Link>
        </div>
      )}
  }
];

// export const Messages = () => {
class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortOptions: [{id:'transmitTime', desc:true}],
     }
  }

  componentWillMount() {
    const {
      orgId
    } = this.props || {};
    this.props.getMessagesByOrgId(orgId);
  }

  componentWillReceiveProps(nextProps) {
    const oldOrg = this.props.orgId;
    const newOrg = nextProps.orgId;

    if (oldOrg === newOrg) return;
    this.props.getMessagesByOrgId(newOrg);
  }

  render(){

    const{
      messages,
      user
    } = this.props || {}

    const{
      isAdmin
    }  = user || {};

    return (
      <Layout route="messages">
        <MetaTags>
          <title>Messages | RogoFire Portal</title>
        </MetaTags>
        <Row>
          <Col md={12}>

            <div className="app-head">

              <div className="app-header">
                <h1>Messages</h1>
              </div>
              {isAdmin &&
                <div className="app-actions">
                  {/* <Link
                    className="btn btn-outline-secondary btn-sm"
                    to={`/messages/send?device=`}
                  >
                    <Icon name="comment" fixedWidth /> Send Message
                  </Link> */}
                </div>
              }
            </div>

            <div className="box-table">
              <Table
                showExport
                showColumnsSelector
                columns={columns}
                data={messages}
                sorted={this.state.sortOptions}
                onSortedChange={val => {
                  this.setState({ sortOptions: val }) }}
              />
            </div>

          </Col>
        </Row>
      </Layout>
    );
  };
};

const mapState = state => {
  const {
    result:messages
  } = state.getMessagesByOrgId.toJS();

  const {orgId} = state.organizations || {};

  const user = (state.login.toJS() || {}).result 

  return {
    messages,
    orgId,
    user
  }
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getDevicesByOrgId: fetchDux.getDevicesByOrgId.createAction,
      getMessagesByOrgId: fetchDux.getMessagesByOrgId.createAction,
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(Messages);