import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '../../ui/Icon';

const required = value => value ? undefined : 'Required';
const maxLength5 = value => (value || '').length <= 5 ? undefined : 'Must be less than 5 characters';

const OrganizationForm = props => {
  const { handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col sm={12}>
          <Field
            name="name"
            type="text"
            component={FormField.Input}
            label="Name"
            validate={[required]}
          />
        </Col>
        <Col sm={12}>
          <Field
            name="slug"
            type="text"
            component={FormField.Input}
            label="Slug"
            validate={[required, maxLength5]}
          />
        </Col>
      </Row>
      <br/>
      <div className="form-actions">
        <Button
          variant="outline-success"
          type="submit"
          size="sm"
          disabled={submitting}
        >
          <Icon name="check" fixedWidth /> Submit
        </Button>
        <Link
          className="btn btn-outline-secondary btn-sm"
          to={`/organizations`}
        >
          <Icon name="times" fixedWidth /> Cancel
        </Link>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'organization'
})(OrganizationForm);
