import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';
import { Route } from 'react-router';

import TopNav from './TopNav';
import SideNav from './SideNav';

const Layout = ({ children, route, user }) => {
  return (
    <div className={classNames(`route-${route}`)}>

      <Route component={TopNav} />

      <Container fluid className="layout container-offcanvas">
        <Row className="row-offcanvas">
          <Col xs={6} md={3} lg={2} className="layout-side-navigation">

            <Route component={SideNav} />

          </Col>
          <Col
            xs={12}
            md={{ span: 9, offset: 3 }}
            lg={{ span: 10, offset: 2 }}
            className="layout-body"
          >

            <main>
              {children}
            </main>

          </Col>
        </Row>
      </Container>

    </div>
  );
};

const mapState = state => ({ user: state.login.toJS().result });
export default connect(mapState)(Layout);
