import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Layout from '../../ui/Layout';
import Box from '../../ui/Box';

import Table from '../../ui/Table';
import Icon from '../../ui/Icon';
import MapComponent from '../../ui/MapComponent';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/orgReducer';
import StatusFilterDropdown, {filterStatus, StatusFilterOptions} from "../../ui/StatusFilterDropdown";

class Blocks extends Component {

  resize() {
    this.setState({hideDesktop: window.innerWidth <= 760});
  } 

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  componentWillMount() {

    const {
      orgId
    } = this.props || {};
  
    this.props.getDevicesByOrgId(orgId);
    this.setState({hideDesktop: window.innerWidth <= 760, statusFilterValue: StatusFilterOptions.ACTIVE});
  }

  componentWillReceiveProps(nextProps) {
    const oldOrg = this.props.orgId;
    const newOrg = nextProps.orgId;

    if (oldOrg === newOrg) return;
    this.props.getDevicesByOrgId(newOrg);
  }

  render() {
    const {
      orgId,
      user
    } = this.props || {};

    // Filter out devices based on status filter
    const devices = this.props.devices ? this.props.devices.filter(({isDeleted}) => filterStatus(this.state.statusFilterValue, !isDeleted)) : [];

    const {
      hideDesktop
    } = this.state || {}

    const{
      isAdmin
    }  = user || {};

    const filteredDevices = (devices || []).filter(({lastKnown})=>lastKnown)

    // TODO fetch the location history for the filtered device set
    // console.log(devices, filteredDevices);
    const locationHistory = new Map(filteredDevices.map(dev => [dev.deviceId, {

    }]));

    const setStatusFilter = (newValue) => {
      this.setState({statusFilterValue: newValue});
    }

    const columns = [
      {
        Header: 'Device IMEI',
        accessor: 'device.imei',
        className: 'text-center',
        Cell: ({ original }) =>{
          const{
            deviceId,
            device:deviceResult
          } = original || {}

          const {
            imei
          } = deviceResult || {}

          return( <Link to={`/blocks/${deviceId}`}>{imei}</Link>)
        }
      }, ...(this.state.statusFilterValue === StatusFilterOptions.ALL ?
      [{
        Header: 'Status',
        accessor: 'isDeleted',
        className: 'text-center',
        Cell: ({ original }) =>{
          const{
            isDeleted
          } = original || {}
          return (
            <span
              className={!isDeleted ? 'device-status active' : 'device-status'}
            >
              {!isDeleted ? 'active' : 'archived'}
            </span>
          )
        }
      }] : []),
      {
        Header: 'Device Name',
        accessor: 'metaData.name',
        Cell: ({ original }) =>{
          const{
            _id:id,
            metaData
          } = original || {}

          const {
            name
          } = metaData || {}

          return( <span>{name}</span>)
        }
      },
      {
        Header: 'Type',
        accessor: 'lastKnown.typeReadable',
        className: 'text-center',
        Cell: ({ original }) => {
          const{
            lastKnown
          } = original || {}

          const{
            typeReadable
          } = lastKnown || {}

          return (
            <span>
              {typeReadable || '-'}
            </span>
          )
        }
      },
      {
        Header: 'Location',
        accessor: 'lastKnown.latitude',
        className: 'text-center',
        Cell: ({ original }) => {
          const{
            lastKnown
          } = original || {}

          const{
            latitude,
            longitude
          } = lastKnown || {}

          return (
            <span>
              {latitude && longitude ? latitude + ', ' + longitude : '-'}
            </span>
          )
        }
      },
      {
        Header: 'Actions',
        accessor: '',
        Cell: ({ original }) =>
        {
          const{
            deviceId,
            isDeleted
          } = original || {}

          return(
            <div className="actions">
              <Link
                className="btn btn-outline-secondary btn-sm"
                to={`/client-portal/${deviceId}`}
              >
                <Icon name="chart-bar" size="sm" fixedWidth />
              </Link>
              <Link
                className="btn btn-outline-secondary btn-sm"
                to={`/blocks/${deviceId}`}
              >
                <Icon name="file" fixedWidth />
              </Link>
              {isAdmin &&
                !isDeleted &&
                <Link
                  className="btn btn-outline-secondary btn-sm"
                  to={`/blocks/${deviceId}/edit`}
                >
                  <Icon name="wrench" fixedWidth />
                </Link>
              }
            </div>
          )
        }
      }
    ];

    return (
      <Layout route="blocks">
        <MetaTags>
          <title>Devices | RogoFire Portal</title>
        </MetaTags>
        <Row>
          <Col md={12}>

            <div className="app-head">

              <div className="app-header">
                <h1>Devices</h1>
              </div>
              {isAdmin &&
                <div className="app-actions">
                  {/* <Link
                    className="btn btn-outline-secondary btn-sm"
                    to={`/messages/send?device=`}
                  >
                    <Icon name="comment" fixedWidth /> Send Message
                  </Link> */}
                  <Link className="btn btn-outline-success btn-sm" to="/blocks/add">
                    <Icon name="plus" fixedWidth /> Add Device
                  </Link>
                </div>
              }

            </div>
            <div className="box-map">
              <MapComponent 
                devices={filteredDevices || []} 
                onlyOne ={(filteredDevices || []).length === 1}
                locationHistory={(filteredDevices || []).length === 1 ? locationHistory : null}
                enableHistory={(filteredDevices || []).length === 1 ? filteredDevices[0].device.breadcrumbEnable : undefined}
              />
            </div>
            {!hideDesktop &&
              <div className="box-table">
                <Table
                  showExport
                  showColumnsSelector
                  extraControls={<StatusFilterDropdown statusFilter={this.state.statusFilterValue} setStatusFilter={setStatusFilter}/>}
                  columns={columns}
                  data={devices}
                  defaultSorted={[{ id: 'device.imei', asc: true }]}
                />
              </div>
            }
          </Col>
          {hideDesktop &&
            <Col md={12}>
              <Box className="box-blocks" header="Devices">
                {(devices || []).map(device => {

                  const{
                    lastKnown,
                    deviceId
                  } = device || {};

                  const{
                    device:deviceResult,
                    metaData
                  } = device || {}

                  const {
                    imei
                  } = deviceResult || {}
                  const {
                    name
                  } = metaData || {}

                  const{
                    typeReadable,
                    latitude,
                    longitude
                  } = lastKnown || {};

                  const fix = latitude && longitude;

                  return(
                    <div className="block" style={{overflow:'hidden'}}>
                      <Link to={`/blocks/${deviceId}`}>
                        <strong>{name || '-'}  ({imei || '-'})</strong>
                        <small>{typeReadable || 'Unknown Type'} - {fix ? 'Location acquired' : 'Location Unknown' }</small>
                      </Link>
                      <Link
                        className="btn btn-outline-secondary btn-md float-right"
                        to={`/client-portal/${deviceId}`}
                        style={{margin:'5px'}}
                      >
                        <Icon name="chart-bar" size="sm" fixedWidth />
                      </Link>
                    </div>
                    
                  )
                }
                )}
              </Box>
            </Col>
          }
        </Row>
      </Layout>
    )
  };
};

const mapState = state => {
  const {
    result:devices
  } = state.getDevicesByOrgId.toJS();

  const {orgId} = state.organizations || {};

  const user = (state.login.toJS() || {}).result 

  return {
    devices,
    orgId,
    user
  }
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getDevicesByOrgId: fetchDux.getDevicesByOrgId.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(Blocks);
