import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button, Modal } from 'react-bootstrap';
import Icon from '../../ui/Icon';

const required = value => value ? undefined : 'Required';
const passwordMatch = (...props) => {
  const {password, password2} = props[1] || {};
  return password !== password2 ? 'Passwords do not match.' : undefined;
}

const SettingsPasswordForm = props => {
  const { handleSubmit, closeModal } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Modal.Body>
        <Field
          name="oldPassword"
          type="password"
          component={FormField.Input}
          label="Old Password"
          validate={[required]}
        />
        <Field
          name="password"
          type="password1"
          component={FormField.Input}
          label="New Password"
          validate={[required]}
        />
        <Field
          name="password2"
          type="password2"
          component={FormField.Input}
          label="Confirm New Password"
          validate={[required, passwordMatch]}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-success" size="sm" type="submit">
          <Icon name="check" fixedWidth /> Save
        </Button>
        <Button variant="outline-secondary" size="sm" onClick={closeModal}>
          <Icon name="times" fixedWidth /> Cancel
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default reduxForm({
  form: 'settings-password'
})(SettingsPasswordForm);
