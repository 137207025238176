import duxFactory from './fetch-dux-factory';
import * as api from '../api/api';

export default {
  login: duxFactory({
    apiFn: api.postLogin,
    actionPrefix: 'POST_LOGIN',
    successListeners: ['POST_LOGIN']
  }),
  validateLogin: duxFactory({
    apiFn: api.validateLogin,
    actionPrefix: 'POST_VALIDATE_LOGIN',
    requestListeners: ['POST_LOGIN'],
    successListeners: ['POST_LOGIN']
  }),
  //start devices
  getDevicesByOrgId: duxFactory({
    apiFn: api.getDevicesByOrgId,
    actionPrefix: 'GET_DEVICE_BY_ORGID'
  }),
  postNewDevice: duxFactory({
    apiFn: api.postNewDevice,
    actionPrefix: 'Add_DEVICE'
  }),
  putDevice: duxFactory({
    apiFn: api.putDevice,
    actionPrefix: 'EDIT_DEVICE',
  }),
  putDeviceOrgId: duxFactory({
    apiFn: api.putDeviceOrgId,
    actionPrefix: 'EDIT_DEVICE_ORGID',
  }),
  getDeviceDetail: duxFactory({
    apiFn: api.getDeviceDetail,
    actionPrefix: 'GET_DEVICE_DETAIL',
  }),
  getDeviceDetailStaged: duxFactory({
    apiFn: api.getDeviceDetail,
    actionPrefix: 'GET_DEVICE_DETAIL_STAGED',
  }),
  deleteDevice: duxFactory({
    apiFn: api.deleteDevice,
    actionPrefix: 'DELETE_DEVICE',
  }),
  //end devices

  //start messages
  getMessagesByOrgId: duxFactory({
    apiFn: api.getMessagesByOrgId,
    actionPrefix: 'GET_MESSAGES_BY_ORG',
  }),
  postSendMessage: duxFactory({
    apiFn: api.postSendMessage,
    actionPrefix: 'POST_SEND_MESSAGE',
  }),
  //end messages

  //start orgs
  getAllOrgs: duxFactory({
    apiFn: api.getAllOrgs,
    actionPrefix: 'GET_ALL_ORGS',
  }),
  getOrgDetails: duxFactory({
    apiFn: api.getOrgDetails,
    actionPrefix: 'GET_ORG_DETAILS',
  }),
  postOrg: duxFactory({
    apiFn: api.postOrg,
    actionPrefix: 'POST_ORG',
  }),
  putOrg: duxFactory({
    apiFn: api.putOrg,
    actionPrefix: 'PUT_ORG',
  }),
  putOrgUsers: duxFactory({
    apiFn: api.putOrgUsers,
    actionPrefix: 'PUT_ORG_USER',
  }),
  deleteOrgUsers: duxFactory({
    apiFn: api.deleteOrgUsers,
    actionPrefix: 'DELETE_ORG_USER',
  }),
  deleteOrg: duxFactory({
    apiFn: api.deleteOrg,
    actionPrefix: 'DELETE_ORG',
  }),
  //end orgs

  //start users
  getAllUsers: duxFactory({
    apiFn: api.getAllUsers,
    actionPrefix: 'GET_ALL_USERS',
  }),
  getAllUsersByOrg: duxFactory({
    apiFn: api.getAllUsersByOrg,
    actionPrefix: 'GET_ALL_USERS_BY_ORG',
  }),
  postNewUser: duxFactory({
    apiFn: api.postNewUser,
    actionPrefix: 'POST_ADD_NEW_USER',
  }),
  putUser: duxFactory({
    apiFn: api.putUser,
    actionPrefix: 'PUT_USER',
  }),
  postUserOrg: duxFactory({
    apiFn: api.postUserOrg,
    actionPrefix: 'POST_USER_ORG',
  }),
  deleteUserOrg: duxFactory({
    apiFn: api.deleteUserOrg,
    actionPrefix: 'DELETE_USER_ORG',
  }),
  deleteUser: duxFactory({
    apiFn: api.deleteUser,
    actionPrefix: 'DELETE_USER',
  }),
  //end users

  //start account
  putAccount: duxFactory({
    apiFn: api.putAccount,
    actionPrefix: 'PUT_ACCOUNT',
  }),
  putAccountPassword: duxFactory({
    apiFn: api.putAccountPassword,
    actionPrefix: 'PUT_ACCOUNT_PASSWORD',
  }),
  getUserAccount: duxFactory({
    apiFn: api.getUserAccount,
    actionPrefix: 'GET_USER',
    successListeners: ['PUT_ACCOUNT']
  }),
  //end account

  //start tokens
  postNewToken: duxFactory({
    apiFn: api.postNewToken,
    actionPrefix: 'POST_ADD_NEW_TOKEN',
  }),
  postEditToken: duxFactory({
    apiFn: api.postEditToken,
    actionPrefix: 'POST_EDIT_TOKEN',
  }),
  getAllTokens: duxFactory({
    apiFn: api.getAllTokens,
    actionPrefix: 'GET_ALL_TOKENS',
  }),
  getToken: duxFactory({
    apiFn: api.getToken,
    actionPrefix: 'GET_TOKEN',
  }),
  deleteToken: duxFactory({
    apiFn: api.deleteToken,
    actionPrefix: 'DELETE_TOKEN',
  }),
  putDeviceToToken: duxFactory({
    apiFn: api.putDeviceToToken,
    actionPrefix: 'PUT_DEVICE_TOKEN',
  }),
  deleteDeviceFromToken: duxFactory({
    apiFn: api.deleteDeviceFromToken,
    actionPrefix: 'DELETE_DEVICE_TOKEN',
  }),
  getTokenDevices: duxFactory({
    apiFn: api.getTokenDevices,
    actionPrefix: 'GET_TOKEN_DEVICES',
  }),
  //end tokens
  
};