import { actionNamespace } from './defaults';

const SET_ORG = `${actionNamespace}/SET_BILLING`;
const CLEAR_ORG = `${actionNamespace}/CLEAR_BILLING`;

const defaultState = null;

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_ORG:
      return payload;
    case CLEAR_ORG:
      return defaultState;
    default:
      return state;
  }
};

export const actionCreators = {
  setOrg: payload => ({ type: SET_ORG, payload }),
  clearOrg: () => ({ type: CLEAR_ORG })
};