import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '../../ui/Icon';

const required = value => value ? undefined : 'Required';

const BlockOrganizationForm = props => {
  const { handleSubmit, handleDelete, submitting, currentUser, orgs } = props;

  const{
    orgNames
  } = currentUser || {}

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="orgToAdd"
        type="text"
        component={FormField.SelectReact}
        label="Search Organizations"
        validate={[required]}
        options={(orgs || []).map(({name:label,_id:value})=>{
          return({label, value})
        })}
      />
      <ul className="form-group-results">
        {(orgNames || []).map(({_id:id, name})=>{
            return(
              <li>
                <div>{name}</div>
                <Button 
                variant="text" 
                size="sm" 
                onClick={()=>handleDelete(id)}
                disabled={submitting}
                >
                  <Icon name="trash" fixedWidth />
                </Button>
                {submitting && <div>updating</div>}
              </li>
            )
          }) }
      </ul>
      <div className="form-actions">
        <Button
          variant="outline-success"
          type="submit"
          size="sm"
          disabled={submitting}
        >
          <Icon name="check" fixedWidth /> Submit
        </Button>
        <Link className="btn btn-outline-secondary btn-sm" to={`/users`}>
          <Icon name="times" fixedWidth /> Cancel
        </Link>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'block-organization'
})(BlockOrganizationForm);
