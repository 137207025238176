import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import Form from './Form';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/orgReducer';
import Icon from "../../ui/Icon";

export class TokenCreate extends Component {

  componentWillMount() {
    this.setState({copied: false});
  }

  componentWillUnmount() {
    this.props.clearPostNewToken();
  }

  render() {

    const {
      postNewToken,
      clearPostNewToken,
      submitting, 
      success,
      result,
      error
    } = this.props || {}

    const handleSubmit = (values, ...rest) => {

      const [props, formFunctions] = rest || []

      const payload = Object.assign({}, values)
      clearPostNewToken();
      this.setState({copied: false});
      postNewToken(payload, null, null, ()=>{
        formFunctions.reset()
      });
    }

    const copySpan = <span style={{cursor: 'pointer'}} onClick={() => {
      navigator.clipboard.writeText(result.key);
      this.setState({copied: true});
    }}>
      <Icon name={"copy"} fixedWidth/>
    </span>

    return (
        <Layout route="token-create">
          <MetaTags>
            <title>Create New Token | RogoFire Portal</title>
          </MetaTags>
          <Row>
            <Col md={12}>
              <div className="app-head">
              <div className="app-header">
                <h1>Create New Token</h1>
              </div>
            </div>
          </Col>
          {success && !submitting &&
            <Col md={8}>
              <div className="alert alert-success alert-dismissible">
                <strong>Success!</strong> The token was successfully added.
                <div>
                  The token key is {result.key}{copySpan}
                  {this.state.copied ? <div>Copied to clipboard!</div> : null}
                </div>
              </div>
            </Col>
          }
          {error && !submitting &&
            <Col md={8}>
              <div className="alert alert-danger   alert-dismissible">
                <strong>Error:</strong> {(error || {}).message}
              </div>
            </Col>
          }
          <Col md={6}>
            <Form 
              onSubmit={handleSubmit}
              submitting={submitting} 
            />
          </Col>
        </Row>
      </Layout>
    );
  }
};

const mapState = state => {
  const {
    inProgress: submitting,
    result,
    error
  } = state.postNewToken.toJS();

  const success = !!result;

  return {
    success,
    result,
    submitting,
    error
  }
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      postNewToken: fetchDux.postNewToken.createAction,
      clearPostNewToken: fetchDux.postNewToken.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(TokenCreate);
