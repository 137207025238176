import React from 'react';
import classNames from 'classnames';

const Icon = (
  {
    prefix,
    name,
    size,
    rotate,
    flip,
    spin,
    fixedWidth,
    stack,
    inverse,
    pulse,
    className,
    style,
    onClick
  }
) => {
  const iconName = `fa-${name}`;

  let sizeClass;
  let rotateClass;
  let flipClass;
  let stackClass;

  if (size) sizeClass = `fa-${size}`;
  if (rotate) rotateClass = `fa-rotate-${rotate}`;
  if (flip) flipClass = `fa-flip-${flip}`;
  if (stack) stackClass = `fa-stack-${stack}`;

  return (
    <span
      onClick={onClick || null}
      style={style}
      className={classNames(
        prefix ? prefix : 'fa',
        iconName,
        { 'fa-fw': fixedWidth },
        { 'fa-spin': spin },
        { 'fa-pulse': pulse },
        { 'fa-inverse': inverse },
        sizeClass,
        rotateClass,
        flipClass,
        stackClass,
        className
      )}
    />
  );
};

export default Icon;
