import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';
import Icon from '../../ui/Icon';
import Table from '../../ui/Table';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import ModalDeleteOrganization from './ModalDeleteOrganization';
import fetchDux from '../../../state/fetch-dux';
import StatusFilterDropdown, {filterStatus, StatusFilterOptions} from "../../ui/StatusFilterDropdown";

const columns = {
  users: [
    {
      Header: 'ID',
      accessor: '_id',
      className: 'text-center',
      Cell: ({ value }) => <Link to={`/users/${value}`}>{value}</Link>
    },
    {
      Header: 'Name',
      accessor: 'lastName',
      className: 'text-center',
      Cell: ({ value, original }) =>{ 
        const{
          firstName,
          lastName
        } = original || {}
        return (<span>{firstName} {lastName}</span>)
      }
    },
    {
      Header: 'Admin',
      accessor: 'isAdmin',
      className: 'text-center',
      Cell: ({ value }) => <Icon name={value ? 'check' : 'times'} fixedWidth />
    },
    {
      Header: 'Actions',
      accessor: '',
      Cell: ({ original }) => (
        <div className="actions">
          <Link
            className="btn btn-outline-secondary btn-sm"
            to={`/users/${(original || {})._id}`}
          >
            <Icon name="file" fixedWidth />
          </Link>
          <Link
            className="btn btn-outline-secondary btn-sm"
            to={`/users/${(original || {})._id}/edit`}
          >
            <Icon name="wrench" fixedWidth />
          </Link>
        </div>
      )
    }
  ],
  blocks: [
    {
      Header: 'IMEI',
      accessor: 'device.imei',
      className: 'text-center',
      Cell: ({ original }) =>{
        const{
          deviceId,
          device
        } = original || {}

        const{
          imei
        } = device || {}
        
        return (<Link to={`/blocks/${deviceId}`}>{imei}</Link>)
      } 
    },
    {
      Header: 'Status',
      accessor: 'isDeleted',
      className: 'text-center',
      Cell: ({ original }) => {
        const{
          isDeleted
        } = original || {}
        return (
          <span
            className={
              !isDeleted ? 'device-status active' : 'block-status'
            }
            style={{color:(!isDeleted ? 'green' : 'red')}}
          >
            {!isDeleted ? 'active' : 'archived'}
          </span>
        )
      }
    },
    {
      Header: 'Device Name',
      accessor: 'metaData.name',
      Cell: ({ original }) => {
        const{
          metaData
        } = original || {}

        const{
          name
        } = metaData || {}
        return (
          <span>
            {name}
          </span>
        )
      }
    },
    {
      Header: 'Type',
      accessor: 'currentMessage.decoded.json.typeReadable',
      className: 'text-center',
      Cell: ({ original }) => {
        const{
          currentMessage
        } = original || {}
        const{
          decoded
        } = currentMessage || {}
        const{
          json
        } = decoded || {}
        const{
          typeReadable
        } = json || {}
        return (
          <span>
            {typeReadable || '-'}
          </span>
        )
      }
    },
    {
      Header: 'Location',
      accessor: 'currentMessage.decoded.json.latitude',
      className: 'text-center',
      Cell: ({ original }) => {
        const{
          currentMessage
        } = original || {}
        const{
          decoded
        } = currentMessage || {}
        const{
          json
        } = decoded || {}
        const{
          longitude,
          latitude
        } = json || {}
        return (<span>{latitude && longitude ? latitude + ', ' + longitude : 'Unknown'}</span>)
      }
    },
    {
      Header: 'Actions',
      accessor: '',
      Cell: ({ value, original }) => (
        <div className="actions">
          <Link
            className="btn btn-outline-secondary btn-sm"
            to={`/blocks/${original.imei}`}
          >
            <Icon name="file" fixedWidth />
          </Link>
          <Link
            className="btn btn-outline-secondary btn-sm"
            to={`/blocks/${original.imei}/edit`}
          >
            <Icon name="wrench" fixedWidth />
          </Link>
        </div>
      )
    }
  ]
};

// export const OrganizationsDetails = ({ showModal, match }) => {

export class OrganizationsDetails extends Component {
  componentWillMount() {
    const {
      match,
    } = this.props || {}
    const {
      params
    } =match || {};
    const{
      id:selectedId
    } = params || {};

    this.props.getOrgDetails(selectedId);
    this.setState({statusFilterValue: StatusFilterOptions.ALL});
  }

  render(){

    const { 
      showModal, 
      org,
      deleteOrg,
      deleteError,
      deleteInProgress
    } = this.props || {};
    
    const {
      users,
      _id:id,
      name
    } = org || {}

    // Filter out devices based on status filter
    const devices = org ? org.devices.filter(({isDeleted}) => filterStatus(this.state.statusFilterValue, !isDeleted)) : [];
    const filteredDeviceColumns = columns.blocks.filter(c => this.state.statusFilterValue === StatusFilterOptions.ALL || c.accessor !== 'isDeleted')

    const usersCount = (users || []).length
    const devicesCount = (devices || []).length

    const handleDelete = ()=>{
      deleteOrg(id, null, null, ()=>{
        window.location='/organizations'
      })
    }

    const setStatusFilter = (newValue) => {
      this.setState({statusFilterValue: newValue});
    }

    return (
      <Layout route="organizations-detail">
        <MetaTags>
          <title>Organization Details | RogoFire Portal</title>
        </MetaTags>
        <Row>
          <Col md={12}>
  
            <div className="app-head">
  
              <div className="app-header">
                <h1>{name} Details</h1>
                <Link className="btn-back" to="/organizations">
                  <Icon name="arrow-left" fixedWidth /> Back
                </Link>
              </div>
  
              <div className="app-actions">
  
                <Link
                  className="btn btn-outline-secondary btn-sm"
                  to={`/organizations/${id}/edit`}
                >
                  <Icon name="wrench" fixedWidth /> Edit
                </Link>
  
                <Link
                  className="btn btn-outline-secondary btn-sm"
                  to={`/organizations/${id}/edit/users`}
                >
                  <Icon name="users" fixedWidth /> Users
                </Link>
  
                {/* <Link
                  className="btn btn-outline-secondary btn-sm"
                  to={`/organizations/${data.id}/edit/blocks`}
                >
                  <Icon name="cube" fixedWidth /> Devices
                </Link> */}
  
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => showModal('DeleteOrganizationModal')}
                >
                  <Icon name="trash" fixedWidth /> Delete
                </Button>
  
              </div>
  
              <ModalDeleteOrganization
                submit={handleDelete}
                org={org}
                deleteError={deleteError}
                deleteInProgress={deleteInProgress}
               />
  
            </div>
  
            <Row>
              {/* <Col sm={6} md={4} lg={3}>
  
                <div className="box-stats">
                  <div>
                    <strong>{data.statistics.incidents || 0}</strong>
                    <div>Active Incidents</div>
                  </div>
                </div>
  
              </Col> */}
              <Col sm={6} md={4} lg={3}>
                <div className="box-stats">
                  <div>
                    <strong>{usersCount || 0}</strong>
                    <div>Users</div>
                  </div>
                </div>
              </Col>
              <Col sm={6} md={4} lg={3}>
                <div className="box-stats">
                  <div>
                    <strong>{devicesCount || 0}</strong>
                    <div>Devices</div>
                  </div>
                </div>
              </Col>
            </Row>
  
            <div className="box-tabs">
              <Tabs defaultActiveKey="users">
                <Tab eventKey="users" title="Users">
                  <Table
                    showExport
                    showColumnsSelector
                    columns={columns.users}
                    data={users || []}
                    defaultPageSize={10}
                  />
                </Tab>
                <Tab eventKey="devices" title="Devices">
                  <Table
                    showExport
                    showColumnsSelector
                    columns={filteredDeviceColumns}
                    extraControls={<StatusFilterDropdown statusFilter={this.state.statusFilterValue} setStatusFilter={setStatusFilter}/>}
                    data={devices || []}
                    defaultPageSize={10}
                    defaultSorted={[{ id: 'device.imei', asc: true }]}
                  />
                </Tab>
                {/* <Tab eventKey="active-incidents" title="Active Incidents" />
                <Tab eventKey="past-incidents" title="Past Incidents" disabled /> */}
              </Tabs>
            </div>
  
          </Col>
        </Row>
      </Layout>
    );
  }
};

const mapState = state => {
  const {
    result:org
  } = state.getOrgDetails.toJS();

  const {
    inProgress:deleteInProgress,
    error:deleteError
  } = state.deleteOrg.toJS();

  return {
    org,
    deleteError,
    deleteInProgress
  }
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getOrgDetails: fetchDux.getOrgDetails.createAction,
      clearGetOrgDetails: fetchDux.getOrgDetails.clearAction,
      getAllUsers: fetchDux.getAllUsers.clearAction,
      deleteOrg: fetchDux.deleteOrg.createAction,
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(OrganizationsDetails);
