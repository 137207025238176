import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import Icon from '../../ui/Icon';

const ModalDeleteOrganization = ({ openModal, closeModal, submit, org, deleteError, deleteInProgress }) => {
  const{
    name,
    slug,
  } = org || {};

  return(
    <Modal show={openModal === 'DeleteOrganizationModal'} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Organization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {deleteError &&
          !deleteInProgress &&
          <div className="alert alert-danger alert-dismissible">
            <strong>Error:</strong> The organization delete failed.
          </div>
        }
        <h4>Are you sure you wish to delete this organization?</h4>
        <br/>
        <p><strong>Name: </strong>&emsp;{name}</p>
        <p><strong>Slug:</strong>&emsp;&emsp;{slug}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" size="sm" onClick={submit} disabled={deleteInProgress}>
          <Icon name="trash" fixedWidth /> Delete
        </Button>
        <Button variant="outline-secondary" size="sm" onClick={closeModal} disabled={deleteInProgress}>
          <Icon name="times" fixedWidth /> Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalDeleteOrganization);
