import React, {Component} from 'react';
import ReactTable from 'react-table';
import { Form, DropdownButton, Dropdown, Row, Col } from 'react-bootstrap';
import Icon from './Icon';
import 'react-table/react-table.css';

const filterData = ({ columns, search, data }) => {
  const s = (search || '').toLowerCase().replace(/\s/g, '');
  const keys = columns.map(({ accessor }) => accessor);
  return (data || []).filter(d => {
    const str = keys
      .reduce((prev, key) => `${prev}${d[key] || ''}`, '')
      .toLowerCase()
      .replace(/\s/g, '');
    return str.indexOf(s) !== -1;
  });
};

const ColumnButton = (
  {
    formattedColumns,
    toggleColumn
  }
) => (
  <DropdownButton
    variant="outline-secondary"
    size="sm"
    id="vendor-columns"
    title="Columns"
    alignRight
    className="checkbox-dropdown"
    style={{ display: 'inline-block', marginLeft: '5px' }}
  >
    {formattedColumns.filter(({ show }) => show).map(({
      Header,
      id,
      accessor
    }, index) => (
      <div
        key={index}
        className="dropdown-item"
        onClick={() =>
          toggleColumn({
            show: false,
            columns: [id || accessor]
          })}
      >
        <Icon name="toggle-on" />
        {Header}
      </div>
    ))}
    {formattedColumns.filter(({ show }) => !show).length > 0 &&
      <Dropdown.Divider />}
    {formattedColumns.filter(({ show }) => !show).map(({
      Header,
      id,
      accessor
    }, index) => (
      <div
        key={index}
        className="dropdown-item"
        onClick={() =>
          toggleColumn({
            show: true,
            columns: [id || accessor]
          })}
      >
        <Icon name="toggle-off" />
        {Header}
      </div>
    ))}
  </DropdownButton>
);

const ExportButton = (
  {
    onExport,
    onExportAll
  }
) => (
  <DropdownButton
    variant="outline-secondary"
    size="sm"
    id="export-columns"
    title="Export"
    alignRight
    className="checkbox-dropdown"
    style={{ display: 'inline-block', marginLeft: '5px' }}
  >
    {/* <div
      className="dropdown-item"
      onClick={() => onExport ? onExport() : null}
    >
      Export
    </div> */}
    <div
      className="dropdown-item"
      onClick={() => onExportAll ? onExportAll() : null}
    >
      Export All
    </div>
  </DropdownButton>
);

class Table extends Component {
  constructor(props) {
    super(props);
    const { columns } = props;
    const activeColumns = (columns || [])
      .filter(({ show }) => show !== false)
      .map(({ id, accessor }) => id || accessor);
    this.state = { activeColumns, searchState: '' };
    this.toggleColumn = this.toggleColumn.bind(this);
  }
  toggleColumn(
    {
      show,
      columns
    }
  ) {
    const { activeColumns } = this.state;
    if (show === true) {
      const next = columns.filter(c => !activeColumns.some(a => a === c));
      return this.setState({ activeColumns: [...activeColumns, ...next] });
    }

    const next = activeColumns.filter(a => !columns.some(c => c === a));
    this.setState({ activeColumns: next });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.columns && this.props.columns && prevProps.columns.length !== this.props.columns.length) {
          // a column list update has occurred - if a column has been removed, remove it from state. If a column has been added, add it.
          // Does not attempt to merge the state of columns in both - state has priority, does not attempt to re-order columns
          const removedColumns = prevProps.columns.filter(c => !this.props.columns.some(nc => nc.accessor === c.accessor));
          const addedColumns = this.props.columns.filter(c => !prevProps.columns.some(pc => pc.accessor === c.accessor));
          const newState = [...this.state.activeColumns.filter(c => !removedColumns.includes(c)), ...addedColumns.map(x => x.accessor)];
          this.setState({activeColumns: newState});
      }
  }

  render() {
    const {
      data,
      columns,
      onSearchChange,
      showSearch,
      search,
      showColumnsSelector,
      extraControls,
      showExport,
      onExport,
      onExportAll,
      defaultSorted,
      ...props
    } = this.props;
    const { activeColumns, searchState } = this.state;
    const filteredData = filterData({
      columns,
      search: onSearchChange ? search: searchState,
      data
    });

    const formattedColumns = columns.map(column => {
      const show = activeColumns.some(
        a => a === (column.id || column.accessor)
      );
      return Object.assign({}, column, { show });
    });

    const handleSearchChange = e => {
      const { value } = e.target || {};
      if (onSearchChange) return onSearchChange(value);
      this.setState({ searchState: value });
    }
    return (
      <div>
        {(showSearch || showColumnsSelector || extraControls || showExport) &&
          <Row className="table-actions">
            {showSearch &&
              <Col>
                <Form.Control
                  type="text"
                  size="sm"
                  placeholder="Search..."
                  className="table-search"
                  onChange={handleSearchChange}
                  value={onSearchChange ? search : searchState}
                />
              </Col>}
            {(showColumnsSelector || showExport) &&
              <Col className="text-right">
                {extraControls}
                {showColumnsSelector && (
                  <ColumnButton
                    formattedColumns={formattedColumns}
                    toggleColumn={this.toggleColumn}
                  />
                )}
                {showExport && (
                  <ExportButton
                    onExport={onExport}
                    onExportAll={onExportAll}
                  />
                )}
              </Col>}
          </Row>}
        <ReactTable
          data={filteredData || []}
          columns={formattedColumns || []}
          {...props}
          defaultSorted={defaultSorted}
        />
      </div>
    );
  }
}

export default Table;
