import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';

import Table from '../../ui/Table';
import Icon from '../../ui/Icon';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';

const columns = [
  {
    Header: 'ID',
    accessor: '_id',
    className: 'text-center',
    Cell: ({ value }) => <Link to={`/organizations/${value}`}>{value}</Link>
  },
  {
    Header: 'Name',
    accessor: 'name'
  },
  {
    Header: 'Slug',
    accessor: 'slug',
    className: 'text-center'
  },
  {
    Header: 'Devices',
    accessor: 'deviceCount',
    className: 'text-center'
  },
  {
    Header: 'Actions',
    accessor: '',
    Cell: ({ original }) => 
    {
      const{
        _id:id
      } = original || {}
      return(
        <div className="actions">
          <Link
            className="btn btn-outline-secondary btn-sm"
            to={`/organizations/${id}`}
          >
            <Icon name="file" fixedWidth />
          </Link>
          <Link
            className="btn btn-outline-secondary btn-sm"
            to={`/organizations/${id}/edit`}
          >
            <Icon name="wrench" fixedWidth />
          </Link>
        </div>
      )
    }
  }
];

const data = [
  {
    id: 3751,
    slug: 'ORG',
    name: 'Organization name',
    blocks: 10
  },
  {
    id: 2323,
    slug: 'ORG',
    name: 'Organization name',
    blocks: 32
  }
];
class Organizations extends Component {
  componentWillMount() {
    const {
      orgId
    } = this.props || {};
    this.props.getAllOrgs(orgId);
  }

  render(){

    const {
      orgs
    } = this.props || {};

    return (
      <Layout route="organizations">
        <MetaTags>
          <title>Organizations | RogoFire Portal</title>
        </MetaTags>
        <Row>
          <Col md={12}>

            <div className="app-head">

              <div className="app-header">
                <h1>Organizations</h1>
              </div>

              <div className="app-actions">
                <Link
                  className="btn btn-outline-success btn-sm"
                  to="/organizations/create"
                >
                  <Icon name="plus" fixedWidth /> Create Organization
                </Link>
              </div>

            </div>

            <div className="box-table">
              <Table
                showExport
                showColumnsSelector
                columns={columns}
                data={orgs}
                defaultPageSize={10}
              />
            </div>

          </Col>
        </Row>
      </Layout>
    );
  };
};

const mapState = state => {
  const {
    result:orgs
  } = state.getAllOrgs.toJS();

  return {
    orgs
  }
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getAllOrgs: fetchDux.getAllOrgs.createAction,
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(Organizations);
