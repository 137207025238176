import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';

import Table from '../../ui/Table';
import Icon from '../../ui/Icon';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/orgReducer';

const columns = [
  {
    Header: 'ID',
    accessor: '_id',
    className: 'text-center',
    Cell: ({ value }) => <Link to={`/users/${value}`}>{value}</Link>
  },
  {
    Header: 'Name',
    accessor: 'lastName',
    className: 'text-center',
    Cell: ({ value, original }) =>{ 
      const{
        firstName,
        lastName
      } = original || {}
      return (<span>{firstName} {lastName}</span>)
    }
  },
  {
    Header: 'Organizations',
    accessor: 'organizations',
        Cell: ({ original }) =>{ 
      const{
        orgNames
      } = original || {}
      return (<span>{((orgNames || []).map(({slug})=> slug) || []).join(', ')}</span>)
    }
  },
  {
    Header: 'Admin',
    accessor: 'isAdmin',
    className: 'text-center',
    Cell: ({ value }) => <Icon name={value ? 'check' : 'times'} fixedWidth />
  },
  {
    Header: 'Actions',
    accessor: '',
    Cell: ({ value, original }) => (
      <div className="actions">
        <Link
          className="btn btn-outline-secondary btn-sm"
          to={`/users/${(original || {})._id}`}
        >
          <Icon name="file" fixedWidth />
        </Link>
        <Link
          className="btn btn-outline-secondary btn-sm"
          to={`/users/${(original || {})._id}/edit`}
        >
          <Icon name="wrench" fixedWidth />
        </Link>
      </div>
    )
  }
];

class Users extends Component {

  componentWillMount() {
    const {
      orgId
    } = this.props || {};

    if(orgId){
      this.props.getAllUsersByOrg(orgId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const oldOrg = this.props.orgId;
    const newOrg = nextProps.orgId;

    if (oldOrg === newOrg) return;
    this.props.getAllUsersByOrg(newOrg);
  }

  render(){

    const {
      orgId,
      users
    } = this.props || {};

    return (
      <Layout route="users">
        <MetaTags>
          <title>Users | RogoFire Portal</title>
        </MetaTags>
        <Row>
          <Col md={12}>

            <div className="app-head">

              <div className="app-header">
                <h1>Users</h1>
              </div>

              <div className="app-actions">
                <Link
                  className="btn btn-outline-success btn-sm"
                  to="/users/create"
                >
                  <Icon name="plus" fixedWidth /> Create User
                </Link>
              </div>

            </div>

            <div className="box-table">
              <Table
                showExport
                showColumnsSelector
                columns={columns}
                data={users}
              />
            </div>

          </Col>
        </Row>
      </Layout>
    );
  };
};

const mapState = state => {
  const {
    result:users
  } = state.getAllUsersByOrg.toJS();

  const {orgId} = state.organizations || {};

  return {
    users,
    orgId
  }
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getAllUsersByOrg: fetchDux.getAllUsersByOrg.createAction,
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(Users);
