import React, { Component } from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
  Polyline, InfoWindow,
} from 'react-google-maps';
import moment from "moment";

const gMapKey = process.env.REACT_APP_GOOGLE_MAPS_KEY || '';

const formatDevices = (inputDevices) => {

  const devices = (inputDevices || [])
  .filter((device) =>{
    const{
      lastKnown
    } = device || {}

    const{
      latitude,
      longitude
    } = lastKnown || {}
    
    return(longitude && latitude);
  })
  .map((device)=>{
    const{
      lastKnown,
      metaData,
      isDeleted
    } = device || {};

    const{
      latitude:lat,
      longitude:lng,
      direction,
      windSpeed,
      nSat
    } = lastKnown || {}
    if(lat && lng){
      return {coords:{lat:Number(lat), lng:Number(lng), nSat:Number(nSat || 3)}, metaData, isDeleted, direction, windSpeed};
    }
  })


  return devices;
}

const convertWind = (windSpeed, places=2) => {
  if(!windSpeed) return null;
  return ((windSpeed + Number.EPSILON) * 2.23694).toFixed(places);
  
}

function degrees_to_radians(degrees)
{
  var pi = Math.PI;
  return degrees * (pi/180);
}

const getArrowPoint = (lat, lng, deg, mag)=>{
  const formattedDeg1 = deg < 0 ? 360 - deg : deg;
  const formattedDeg2 = formattedDeg1 > 360 ? deg - 360 : deg;
  const rads = degrees_to_radians(formattedDeg2);
  const xComp = Math.cos(rads) * mag;
  const yComp = Math.sin(rads) * mag;
  return {lat:Number(lat + xComp), lng:Number(lng + yComp)};
};

class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.updateBounds = this.updateBounds.bind(this);

  }

  getControls() {
    // The API does not expose the controls variable, which is how you add custom controls per Google documentation
    // hopefully I won't be fired!
    return this.refs.map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.controls
  }

  componentDidMount() {
    this.updateBounds(this.props);
    // default to zoom 11 & average latitude of all devices
    const lats = this.props.devices.filter(x => !!x.lastKnown && (x.lastKnown.latitude !== 0 || x.lastKnown.longitude !== 0)).map(x => x.lastKnown.latitude);
    this.setState({zoom: 11, timeWindow: 24, type: window.google.maps.MapTypeId.HYBRID, lat: lats.length ? lats.reduce((a,b) => a + b) / lats.length : 0, activeTooltip: null});
    window.map = this.refs.map;
    /* eslint-disable-next-line no-undef */
    if (this.props.locationHistory) {
      this.getControls()[window.google.maps.ControlPosition.TOP_LEFT].push(document.getElementById("timeSelect"))
    }
    // this.refs.map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById("fakeLegend"));
  }
  componentWillReceiveProps(nextProps) {
    this.updateBounds(nextProps);
  }
  updateBounds(props) {

    const { devices: unfortmattedDevices, onlyOne } = props || {};

    const devices = formatDevices(unfortmattedDevices || []) ;

    var bounds = new window.google.maps.LatLngBounds();
    (devices || []).filter(({ coords }) => coords.lat && coords.lng).map(({
      coords
    }, i) => {
      const circle = new window.google.maps.Circle({
        center: { lat: Number(coords.lat), lng: Number(coords.lng) },
        radius: ((onlyOne ? 80000 : 100) / Number(coords.nSat))
      });
      const b = circle.getBounds();

      const neBound = new window.google.maps.LatLng(
        b.getNorthEast().lat(),
        b.getNorthEast().lng()
      );

      const swBound = new window.google.maps.LatLng(
        b.getSouthWest().lat(),
        b.getSouthWest().lng()
      );
      bounds.extend(neBound);
      bounds.extend(swBound);
    });

    this.refs.map.fitBounds(bounds);
  }

  render() {

    const { devices: unfortmattedDevices, onlyOne, locationHistory, enableHistory } = this.props || {};

    const showHistory = enableHistory === undefined ? true : !!enableHistory;

    const devices = formatDevices(unfortmattedDevices || []);

    // TODO track lat too for the mercator projection. Default of the average of the devices?
    const {zoom, lat, type, activeTooltip, timeWindow} = this.state || {type: 'hybrid'};

    //mercator projection - https://groups.google.com/g/google-maps-js-api-v3/c/hDRO4oHVSeM/m/osOYQYXg2oUJ?pli=1
    const metersPerPixel = 156543.03392 * Math.cos(lat * Math.PI / 180) / Math.pow(2, zoom)

    if ((!onlyOne || devices.length > 1) && !!locationHistory && locationHistory.length > 0) {
      // eslint-disable-next-line no-console
      console.warn("Device history / breadcrumb not supported for >1 device yet")
    } else {
      // eslint-disable-next-line no-console
      // console.info("Map component got history: ", locationHistory)
    }

    // TODO match the dot colors & the circle colors & the line colors when multiple devices
    const colorMap = {
      red: {
        icon:  '//maps.google.com/mapfiles/ms/icons/red-dot.png',
        roadmap: {
          circle: '#FF0000',
          line: '#be0202',
          border: '#000000'
        }, satellite: {
          circle: '#FF0000',
          line: '#be0202',
          border: '#FFFFFF'
        }

      },
      yellow: {
        icon: '//maps.google.com/mapfiles/ms/icons/yellow-dot.png',
        roadmap: {
          circle: '#FFFF00',
          line: '#888800',
          border: '#000000'
        }, satellite: {
          circle: '#FFFF00',
          line: '#888800',
          border: '#000000'
        }
      }, wind: {
        roadmap: {
          stroke: '#212121',
          fill: '#212121'
        },
        satellite: {
          // #06fff7 also looks ok
          stroke: '#f7ff06',
          fill: '#212121'
        }
      }
    }
    for (let k in colorMap) {
      const obj = colorMap[k];
      obj.terrain = obj.roadmap;
      obj.hybrid = obj.satellite;
    }


    // instead of tooltips:
    // https://developers.google.com/maps/documentation/javascript/examples/infowindow-simple-max

    const locationMap = {};
    const minTime = Date.now() - (60 * 60 * 1000 * timeWindow);
    // TODO ensure map zooms such that all points render
    for (let k in showHistory ? (locationHistory || []) : []) {
      locationMap[k] = {circles:[], lines: []};
      let last = {latitude: NaN, longitude: NaN};
      const filtered = locationHistory[k].filter(x => !Number.isNaN(x.latitude) && !Number.isNaN(x.longitude) && (x.latitude !== 0 || x.longitude !== 0) && new Date(x.timestamp).getTime() >= minTime)
          .sort((a,b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
      for (let i = 0; i < filtered.length; i++) {
        const temp = filtered[i];
        if (temp.latitude !== last.latitude || temp.longitude !== last.longitude) {

          // TODO the marker vs circle with zoom listener?
          // TODO use the zoom state with the circle now - zoom * 2 * base_radius (1)?
          locationMap[k].circles.push(
              <Circle
                  key={k + "_" + temp.timestamp}
                  center={{ lat: Number(temp.latitude), lng: Number(temp.longitude) }}
                  title={`${temp.timestamp}`}
                  options={{
                    strokeColor: devices.isDeleted ? colorMap.yellow[type].border : colorMap.red[type].border,
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: devices.isDeleted ? colorMap.yellow[type].circle : colorMap.red[type].circle,
                    fillOpacity: 1,
                    zIndex: 20
                  }}
                  //* Draw each point as a circle that stays the same size on screen regardless of zoom, out until zoom level 9.
                  // TODO merge points that will overlap by > radius
                  radius={40 * Math.pow(2, (15 - Math.max(zoom, 9)))}
                  onClick={() => this.setState({activeTooltip: activeTooltip && activeTooltip.lat === temp.latitude && activeTooltip.lon === temp.longitude ? null : {lat: temp.latitude, lon: temp.longitude, content: moment(temp.timestamp).format('MM/DD/YYYY HH:mm:ss')}})}
                  >

              </Circle>

          );
          if (i > 0 && !Number.isNaN(last.latitude + last.longitude) && last.latitude !== 0 && last.longitude !== 0) {
            const trigDist = Math.hypot(temp.latitude - last.latitude, temp.longitude - last.longitude); // this is a rough approx for the sole purpose of checking if the distance is very small
            // show if trig dist > .01 at zoom 12
            const showArrows = trigDist * Math.pow(2, (zoom - 12)) > .02;
            // TODO redo arrows as triangles instead of the icon
            locationMap[k].lines.push(
                <Polyline
                  path={[{ lat: Number(last.latitude), lng: Number(last.longitude) }, { lat: Number(temp.latitude), lng: Number(temp.longitude)}]}
                  key={k + "_" + temp.timestamp + "_line_border"}
                  options={{
                    strokeColor: devices.isDeleted ? colorMap.yellow[type].border : colorMap.red[type].border,
                    strokeWeight: 6,
                    // TODO: Show this based on rendered length of line? (ie based on zoom * l)
                    icons: showArrows ?  [{
                      icon: {path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW},
                      offset: '40%',
                      zIndex: 15
                    }] : []
                  }}
                />
            );
            // line border i
            locationMap[k].lines.push(
                <Polyline
                    path={[{ lat: Number(last.latitude), lng: Number(last.longitude) }, { lat: Number(temp.latitude), lng: Number(temp.longitude)}]}
                    key={k + "_" + temp.timestamp + "_line"}
                    options={{
                      strokeColor: devices.isDeleted ? colorMap.yellow[type].line : colorMap.red[type].line,
                      strokeWeight: 4,
                      // TODO: Show this based on rendered length of line? (ie based on zoom * l)
                      icons: showArrows ?  [{
                        icon: {path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW},
                        offset: '40%',
                        zIndex: 16
                      }] : []
                    }}
                />
            );
          }
          last = temp;
        }
      }
    }

  // TODO react-google-maps hasnt been touched since 2018. Replace with google-maps-react???

    return (
      <GoogleMap
        defaultZoom={8}
        // defaultCenter={{ lat: -34.397, lng: 150.644 }}
        ref="map"
        mapTypeId={type}
        onMapTypeIdChanged={(e) => this.setState({type: this.refs.map.getMapTypeId()})}
        onZoomChanged={() => this.setState({zoom: this.refs.map.getZoom()})}
        options={{scaleControl: true}}
      >
        {(devices || [])
          .map(({ coords: { lat, lng }, metaData, isDeleted, windSpeed, direction }, idx) =>{
            const{
              name
            } = metaData || {}

            const stringDesc = (windSpeed && direction) ? `Device: ${name || '-'}\nWind: ${convertWind(windSpeed)} Mph\nDirection: ${direction} deg` : `Device: ${name || '-'}`

            return(
              <Marker
                position={{ lat: Number(lat), lng: Number(lng) }}
                key={idx}
                title={stringDesc}
                icon={{url:isDeleted ? colorMap.yellow.icon : colorMap.red.icon }}
              />
            )
          })}
        {(devices || [])
          .map(({ coords: { lat, lng }, isDeleted, direction }, idx) =>{
            if(
                isDeleted ||
                !direction ||
                (direction > 360 || direction < 0) ||
                !onlyOne
              )
              return;
            const arrowHeadPoint = getArrowPoint(lat, lng, direction, 0.03);

            return(
              <Polyline
                path={[{ lat: Number(lat), lng: Number(lng) }, arrowHeadPoint ]}
                key={idx}
                options={{
                  strokeColor: colorMap.wind[type].stroke,
                  strokeWeight: 4,
                  fillColor: colorMap.wind[type].fill,
                  icons: [{
                    icon: {path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW},
                    offset: '100%'
                  }]
                }}
              />
            )
          })}
        {/*{(devices || []).map(({ coords: { lat, lng, nSat } }, idx) => (*/}
        {/*  <Circle*/}
        {/*    key={idx}*/}
        {/*    center={{ lat: Number(lat), lng: Number(lng) }}*/}
        {/*    options={{*/}
        {/*      strokeColor: '#FF0000',*/}
        {/*      strokeOpacity: 0.8,*/}
        {/*      strokeWeight: 2,*/}
        {/*      fillColor: '#FF0000',*/}
        {/*      fillOpacity: 0.05*/}
        {/*    }}*/}
        {/*    radius={(100 / Number(nSat))}*/}
        {/*  />*/}
        {/*))}*/}
        {(devices || []).map(dev => (locationMap[dev.metaData.deviceId] || {lines: []}).lines)}
        {(devices || []).map(dev => (locationMap[dev.metaData.deviceId] || {circles: []}).circles)}
        {activeTooltip ?
            // .gm-style-iw.gm-style-iw-c button {display: none} & listen for all clicks to clear it?
          <InfoWindow visible={false} onCloseClick={() => this.setState({activeTooltip: null})} id={"testInfoWindow"}
                      position={{ lat: Number(this.state.activeTooltip.lat), lng: Number(this.state.activeTooltip.lon) }}>
            <div onClick={() => this.setState({activeTooltip: null})}>{activeTooltip.content}</div>
          </InfoWindow> : null
        }
        {showHistory && !!locationHistory ?
        <select id={"timeSelect"} style={{position: 'absolute', top: -9999}} value={timeWindow} onChange={(e) => {
          this.setState({timeWindow: e.target.value, activeTooltip: null})
        }}>
          <option value={24}>24 hours</option>
          <option value={12}>12 hours</option>
          <option value={6}>6 hours</option>
          <option value={3}>3 hours</option>
          <option value={1}>1 hour</option>
          <option value={0}>0 hours</option>
        </select> : null}
      </GoogleMap>
    );
  }
}

const ConnectedMap = withScriptjs(withGoogleMap(MapComponent));

const MapWrapper = props => (
  <ConnectedMap
    {...props}
    googleMapURL={`//maps.googleapis.com/maps/api/js?key=${gMapKey}&amp;v=3.exp&amp;libraries=geometry,drawing,places`}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={
      <div
        style={{
          height: `${props.containerHeight ? props.containerHeight : 600}px`
        }}
      />
    }
    mapElement={<div style={{ height: `100%` }} />}
  />
);

export default MapWrapper;
