import React, {useEffect, useState} from 'react';
import MetaTags from 'react-meta-tags';
import {Row, Col, Button, Modal, Form} from 'react-bootstrap';
import Layout from '../../ui/Layout';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/orgReducer';

import Icon from "../../ui/Icon";
import Table from "../../ui/Table";
import {postEditToken} from "../../../api/api";

const getColumns = (deleteAction) => [
    {
        Header: 'IMEI',
        accessor: 'imei',
        className: 'text-center',
        Cell: ({ value }) => <span>{value}</span>
    },
    {
        Header: 'Current Org',
        accessor: 'org.name',
        className: 'text-center',
        Cell: ({ value }) => <span>{value}</span>
    },
    {
        Header: 'Current Name',
        accessor: 'metaJoin.name',
        Cell: ({ value }) => <span>{value}</span>
    },
    {
        Header: 'Type',
        accessor: 'lastMessage.decoded.json.typeReadable',
        className: 'text-center',
        Cell: ({ value }) => <span>{value}</span>
    },
    {
        Header: 'Last Heard',
        accessor: 'lastMessage.transmitTime',
        className: 'text-center',
        Cell: ({ value }) => <span>{value ? new Date(value).toLocaleString() : ""}</span>
    },
    {
        Header: 'Actions',
        accessor: '',
        Cell: ({ original }) => (
            <div className="actions text-center">
                <Button
                    variant={"outline-secondary"}
                    size={"sm"}
                    onClick={() => deleteAction(original.tokenId, original.imei)}
                >
                    <Icon name="trash" fixedWidth />
                </Button>
            </div>
        )
    }
];

function EditModal({show, setShow, token, editToken}) {
    if (!token) return null;
    return <Modal show={show}>
        <Modal.Header>Edit Token</Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group className="mb-3" controlId="nameInput">
                    <Form.Label>Name</Form.Label>
                    <Form.Control defaultValue={token.name}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="emailInput">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" defaultValue={token.email}/>
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant={'primary'} onClick={() => editToken(
                document.getElementById("nameInput").value,
                document.getElementById("emailInput").value)}>Save</Button>
            <Button variant={'secondary'} onClick={() => setShow(false)}>Cancel</Button>
        </Modal.Footer>
    </Modal>
}

export const TokenEdit = ({
    token,
    match,
    devices,
    error,
    putError,
    getToken,
    getTokenDevices,
    postEditToken,
    putDeviceToToken,
    deleteDeviceFromToken,
    inProgress
}) => {

    const { params } = match || {};
    const { id:tokenId } = params || {};

    const [showEdit, setShowEdit] = useState(false);

    useEffect(() => {
        if (putError) {
            alert("Error adding device: " + putError.message);
        }
    }, [putError]);

    useEffect(() => {
        // query parameter updates dont result in a refetch, so apply an effect to the tokenId to update
        getToken(tokenId);
        getTokenDevices(tokenId);
    }, [tokenId]);

    if (!token && tokenId && !inProgress && !error) {
        getToken(tokenId);
    }

    if (!devices && token && !inProgress && !error) {
        getTokenDevices(tokenId);
    }

    const addDevice = () => {
        const imeiInput = document.getElementById("addImeiInput");
        putDeviceToToken({tokenId: token._id, imei: imeiInput.value}, null, null, () => {
            if (token && !error) {
                getTokenDevices(tokenId);
            }
        });
        imeiInput.value = "";
    }

    return <Layout route="users-edit">
        <MetaTags>
            <title>Edit Token | RogoFire Portal</title>
        </MetaTags>
        <EditModal show={showEdit} setShow={setShowEdit} token={token} editToken={(name, email) => {
            postEditToken({tokenId: tokenId, name: name, email: email});
            setShowEdit(false);
            getToken(tokenId);
        }}/>
        <Row>
            <Col md={10}>
              <div className="app-head">
                <div className="app-header">
                  <h1>Edit Token Devices</h1>
                </div>
              </div>
            </Col>
            <Col md={2} className={"text-right"}>
                <Button variant={'outline-primary'} onClick={() => setShowEdit(true)}>Edit Token</Button>
            </Col>
        </Row>
        {error ? <Row><Col md={12} className={"alert alert-danger text-center"}>Error loading token data!</Col> </Row> : null}
        <Row><Col>
            <div>
                {`Configure the list of devices accessible by API Token "${token ? token.name : null}" (${token ? token.email : null}):`}<br/><br/>
            </div>
        </Col><Col>
            <div style={{float: "right"}}>
                Add By IMEI: <input id="addImeiInput" type="text" width={32} onKeyUp={(e) => {if (e.key === 'Enter') addDevice()}}/>
                <button className="btn btn-success btn-sm" style={{marginLeft:'10px', position: 'relative', top: '-2px'}} onClick={addDevice}>Add</button>
            </div>

        </Col></Row>
        <Row><Col>
            <div className="box-table">
                <Table
                    columns={getColumns((tId, imei) => {
                        deleteDeviceFromToken({tokenId: tId, imei: imei}, null, null, () => {
                            if (token && !error) {
                                getTokenDevices(tokenId);
                            }
                        });
                    })}
                    data={devices}
                />
            </div>
        </Col></Row>
    </Layout>
};

const mapState = state => {
    const {
        inProgress: putDeviceSubmitting,
        result,
        error: putError
    } = state.putDeviceToToken.toJS();

    const {
        result: devices,
        inProgress: getTokenDevicesInProgress,
        error: getTokenDevicesError,
    } = state.getTokenDevices.toJS();

    const {
        result: token,
        inProgress: getTokenInProgress,
        error: getTokenError
    } = state.getToken.toJS();

    const inProgress = putDeviceSubmitting || getTokenDevicesInProgress || getTokenInProgress;
    const error = getTokenError || getTokenDevicesError;

    return {
        token,
        inProgress,
        error,
        putError,
        devices,
        result
    }
};

const mapDispatch = dispatch => bindActionCreators({
        ...actionCreators,
        getToken: fetchDux.getToken.createAction,
        getTokenDevices: fetchDux.getTokenDevices.createAction,
        putDeviceToToken: fetchDux.putDeviceToToken.createAction,
        deleteDeviceFromToken: fetchDux.deleteDeviceFromToken.createAction,
        postEditToken: fetchDux.postEditToken.createAction
    }, dispatch);

export default connect(mapState, mapDispatch)(TokenEdit);
