import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import Icon from '../../ui/Icon';

const ModalDeleteUser = ({ 
  openModal, 
  closeModal, 
  submit,
  currentUser, 
  deleteInProgress,
  deleteError 
}) => {

  const {
    firstName,
    lastName,
    _id:id
  } = currentUser || {}

  return(
    <Modal show={openModal === 'DeleteUserModal'} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Delete User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {deleteError &&
          !deleteInProgress &&
          <div className="alert alert-danger alert-dismissible">
            <strong>Error:</strong> The user could not be deleted.
          </div>
        }
        <h4>Are you sure you wish to delete this user?</h4>
        <br/>
        <p><strong>Name: </strong>&emsp;{firstName} {lastName}</p>
        <p><strong>Id:</strong>&emsp;&emsp;&emsp;{id}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" size="sm" onClick={()=>submit(id)} disabled={deleteInProgress}>
          <Icon name="trash" fixedWidth /> Delete
        </Button>
        <Button variant="outline-secondary" size="sm" onClick={closeModal} disabled={deleteInProgress}>
          <Icon name="times" fixedWidth /> Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalDeleteUser);
