import React from 'react';
import Icon from './Icon';
import {DropdownButton} from "react-bootstrap";

// raw JS approach to an enum type:
export const StatusFilterOptions = {
    ACTIVE: 'Active',
    ARCHIVED: 'Archived',
    ALL: 'All'
}

export function filterStatus(filterOption, isActive) {
    // @warning - table renders status based on isDeleted NOT isActive!
    if (filterOption === StatusFilterOptions.ACTIVE) return !!isActive;
    if (filterOption === StatusFilterOptions.ARCHIVED) return !isActive;
    return true;
}

export default function ({statusFilter, setStatusFilter}) {
    return <DropdownButton variant="outline-secondary" size="sm" id="status-filter" title={`Device Status: ${statusFilter}`}
                           alignRight className="checkbox-dropdown" style={{display: 'inline-block'}}
    >
        {Object.entries(StatusFilterOptions).map(([k,item], index) => (
            <div key={index} className="dropdown-item" style={{cursor: "pointer"}} onClick={() => {
                if (setStatusFilter) {
                    setStatusFilter(item);
                }
            }}>
                <Icon name={statusFilter === item ? "check" : "blank"} className={"fa-fw"}/>
                {item}
            </div>
        ))}
    </DropdownButton>
}