import React from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import Form from './FormPassword';

const ModalDeleteUser = ({ openModal, closeModal, submit, submitting, error }) => (
  <Modal show={openModal === 'UpdatePasswordModal'} onHide={closeModal}>
    <Modal.Header closeButton>
      <Modal.Title>Update Your Password</Modal.Title>
    </Modal.Header>
    {!submitting &&
      error &&
        <div class="alert alert-danger  alert-dismissible">
          <strong>Error:</strong> {(error || {}).message}
        </div>
    }
    <Form       
      onSubmit={submit}
      closeModal={closeModal}
      submitting={submitting}
    />
  </Modal>
);

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalDeleteUser);
