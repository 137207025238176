import React from 'react';
import MetaTags from 'react-meta-tags';
import { Redirect } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import authorized from '../../../authorized';
import { Container, Row, Col, Alert } from 'react-bootstrap';

import LoginForm from './LoginForm';

export const Login = ({ user, error, location, postLogin }) => {
  const isAuthorized = authorized({
    user
  });

  if (isAuthorized) return <Redirect to={location.from || { pathname: '/blocks' }} />;
  return (
    <div className="route-login">
      <MetaTags>
        <title>Login | RogoFire Portal</title>
      </MetaTags>
      <Container fluid>
        <Row className="justify-content-sm-center align-items-center">
          <Col sm md={6} lg={4}>
            {error && <Alert bsStyle="warning">{error.message}</Alert>}
            <LoginForm onSubmit={values => postLogin(values)} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapState = state => {
  const {
    result: user,
    error
  } = state.login.toJS();
  return {
    user,
    error
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      postLogin: fetchDux.login.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(Login);
