import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '../../ui/Icon';
import Box from '../../ui/Box';

const required = value => value ? undefined : 'Required';

const ApiForm = props => {
  const { handleSubmit, submitting } = props;
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Field
            name="name"
            type="text"
            component={FormField.Input}
            label="Name"
            validate={[required]}
        />
        <Field
            name="email"
            type="email"
            component={FormField.Input}
            label="Email"
            validate={[required]}
        />
        <div className="form-actions">
          <Button
            variant="outline-success"
            type="submit"
            size="sm"
            disabled={submitting}
          >
            <Icon name="check" fixedWidth /> Submit
          </Button>
          <Link className="btn btn-outline-secondary btn-sm" to={`/tokens`}>
            <Icon name="times" fixedWidth /> Cancel
          </Link>
        </div>
      </form>
    </Box>
  );
};

export default reduxForm({
  form: 'token'
})(ApiForm);
