 import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col, Button } from 'react-bootstrap';
import Layout from '../../ui/Layout';
import Box from '../../ui/Box';
import Icon from '../../ui/Icon';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';

import ModalUpdateProfile from './ModalUpdateProfile';
import ModalUpdatePassword from './ModalUpdatePassword';

import fetchDux from '../../../state/fetch-dux';


class Settings extends Component {
  componentWillUnmount() {
  
    this.props.clearPutAccount();
    this.props.clearPutAccountPassword();
  }
  
  render(){

    const {
      showModal, 
      // user,
      putAccount,
      clearPutAccount,
      putAccountPassword,
      clearPutAccountPassword,
      closeModal,
      result,
      error,
      submitting,
      userAccount
    } = this.props || {}
  
    const {
      firstName,
      lastName,
      email,
      orgNames:orgs,
      _id:id,
      isAdmin
    } = userAccount || {}
  
    const handleEdit = ({firstName, lastName, email})=>{
      clearPutAccount();
      clearPutAccountPassword();
      putAccount({firstName, lastName, email, userId:id}, null, null, ()=>{
        closeModal();
      });
    }
  
    const handlePassword = ({password, oldPassword})=>{
      clearPutAccount();
      clearPutAccountPassword();
      putAccountPassword({password, oldPassword, userId:id}, null, null, ()=>{
        closeModal();
      });
    }
  
    const initialValues = {
      firstName,
      lastName,
      email
    }

    return (
      <Layout route="settings">
        <MetaTags>
          <title>Settings | RogoFire Portal</title>
        </MetaTags>
        <Row>
          <Col md={12}>
  
            <div className="app-head">
              <div className="app-header">
                <h1>Account Settings</h1>
              </div>
              <div className="app-actions">
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => showModal('UpdateProfileModal')}
                >
                  <Icon name="wrench" fixedWidth /> Edit Account
                </Button>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => showModal('UpdatePasswordModal')}
                >
                  <Icon name="lock" fixedWidth /> Change Password
                </Button>
                <ModalUpdateProfile 
                  submit={handleEdit}
                  initialValues={initialValues}
                  error={error}
                  submitting={submitting}
                />
                <ModalUpdatePassword
                  submit={handlePassword}
                  user={userAccount}
                  error={error}
                  submitting={submitting}
                />
              </div>
            </div>
          </Col>
          {result &&
              !submitting &&
              <Col md={8}>
                <div className="alert alert-success alert-dismissible">
                  <strong>Success!</strong> Your account was successfully updated.
                </div>
              </Col>
            }
          <Col md={8} lg={6}>
            <Box>
              <div className="box-data">
                <strong>Name</strong>
                <div>{firstName} {lastName}</div>
              </div>
              <div className="box-data">
                <strong>Email</strong>
                <div>{email || '-'}</div>
              </div>
              <div className="box-data">
                <strong>Organizations</strong>
                <div>{(orgs || []).map(({slug})=>slug).join(', ')}</div>
              </div>
              {isAdmin &&
                <div className="box-data danger">
                  <Icon name="user-cog" /> Your account has admin privileges
                </div>}
            </Box>
          </Col>
        </Row>
      </Layout>
    );
  };
};

const mapState = state => {

  // const user = (state.login.toJS() || {}).result 

  const userAccount = (state.getUserAccount.toJS() || {}).result ;

  const {
    result: putAccountResult,
    inProgress: putAccountInProgress,
    error: putAccountError
  } = (state.putAccount.toJS() || {}) 
  const {
    result: putAccountPasswordResult,
    inProgress: putAccountPasswordInProgress,
    error: putAccountPasswordError
  } = (state.putAccountPassword.toJS() || {}) 

  const result = putAccountResult || putAccountPasswordResult
  const error = putAccountError || putAccountPasswordError
  const submitting = putAccountInProgress || putAccountPasswordInProgress

  return {
    // user,
    result,
    error,
    submitting,
    userAccount
  }
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      putAccount: fetchDux.putAccount.createAction,
      clearPutAccount: fetchDux.putAccount.clearAction,
      putAccountPassword: fetchDux.putAccountPassword.createAction,
      clearPutAccountPassword: fetchDux.putAccountPassword.clearAction,
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(Settings);
