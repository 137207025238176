import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';
import {Row, Col, Modal, Button} from 'react-bootstrap';
import Layout from '../../ui/Layout';
import { Link } from 'react-router-dom';

import Table from '../../ui/Table';
import Icon from '../../ui/Icon';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import * as api from '../../../api/api';
import { actionCreators } from '../../../state/orgReducer';
import {postRegenToken} from "../../../api/api";

const getColumns = (component) => [
    {
        Header: 'Name',
        accessor: 'name',
        className: 'text-center',
        Cell: ({ value }) => <span>{value}</span>
    },
    {
        Header: 'Email',
        accessor: 'email',
        className: 'text-center',
        Cell: ({ value }) => <span>{value}</span>
    },
    {
        Header: 'Token',
        accessor: 'key',
        className: 'text-center',
        Cell: ({ value, original }) => <Button type={"button"} variant={"secondary"} size={"sm"} onClick={() => component.setState({
            showModal: true, modalData: {
                header: `Token Key for ${original.name}`,
                body: `${value}`,
                type: 'SHOW'
            }
        })}>Show</Button>
    },
    {
        Header: 'Creation Time',
        accessor: 'createdAt',
        className: 'text-center',
        Cell: ({ value }) => <span>{new Date(value).toLocaleString()}</span>
    },
    {
        Header: 'Device Count',
        accessor: 'deviceCount',
        className: 'text-center',
        Cell: ({ value }) => <span>{value}</span>
    },
    {
        Header: 'Hits',
        accessor: 'hitCount',
        className: 'text-center',
        Cell: ({ value }) => <span>{value}</span>
    },
    {
        Header: 'Actions',
        accessor: '',
        Cell: ({ original }) => (
            <div className="actions text-center">
                <Link className="btn btn-outline-secondary btn-sm" to={`/tokens/${(original || {})._id}`} >
                    <Icon name="wrench" fixedWidth />
                </Link>
                <Button type="button" variant={"outline-secondary"} size={"sm"} onClick={() => component.setState({
                    showModal: true, modalData: {
                        header: `Delete token ${original.name}?`,
                        type: 'DELETE',
                        tokenId: original._id
                    }
                })}>
                    <Icon name="trash" fixedWidth />
                </Button>
                <Button type="button" variant={"outline-secondary"} size={"sm"} onClick={() => component.setState({
                    showModal: true, modalData: {
                        header: `Regenerate API Token for ${original.name}?`,
                        type: 'REGENERATE',
                        tokenId: original._id
                    }
                })}>
                    <Icon name="sync" fixedWidth />
                </Button>
            </div>
        )
    }
];

function TokenOpModal({show, modalData, setModalData, hide, deleteTokenFn, getAllTokensFn}) {
    let body;
    let footer;
    switch (modalData.type) {
        case 'DELETE':
            body =  "This will permanently delete the token and any client apps using it will immediately lose access.";
            break;
        case 'REGENERATE':
            body =  "This will change the key used for authentication. Any client apps using it will immediately lose access until they replace it with the new one.";
            break;
        case 'SHOW':
            body = <>
                <code>{modalData.body}</code>
                <span style={{cursor: 'pointer'}} onClick={() => {
                    navigator.clipboard.writeText(modalData.body);
                    setModalData(Object.assign({}, modalData, {copied: true}));
                }}><Icon name={"copy"} fixedWidth/></span>
                {modalData.copied ? <div>Copied to clipboard!</div> : null}
            </>
            break;
    }

    if (modalData.type === 'SHOW') {
        footer = <Button type={"button"} variant={"primary"} onClick={hide}>Close</Button>;
    } else {
        let btn2;
        if (modalData.type === 'DELETE') {
            btn2 = <Button type={"button"} variant={"danger"} onClick={async () => {
                await deleteTokenFn(modalData.tokenId);
                await getAllTokensFn();
                hide();
            }}>Delete</Button>
        } else if (modalData.type === 'REGENERATE') {
            btn2 = <Button type={"button"} variant={"warning"} onClick={async () => {
                const newValue = await api.postRegenToken(modalData.tokenId);
                setModalData({
                    header: `Token Key for ${newValue.name}`,
                    body: `${newValue.key}`,
                    type: 'SHOW'
                });
                getAllTokensFn();
            }}>Regenerate</Button>
        }
        footer = <>
            <Button type={"button"} variant={"secondary"} onClick={hide}>Cancel</Button>
            {btn2}
        </>
    }

    return <Modal show={show}>
        <Modal.Header>{modalData.header}</Modal.Header>
    <Modal.Body>{body}</Modal.Body>
    <Modal.Footer>{footer}</Modal.Footer>
</Modal>
}

class TokenList extends Component {

    componentWillMount() {
        this.props.getAllTokens();
        this.setState({showModal: false, modalData: {}});
    }

    render(){

    const {
        tokens
    } = this.props || {};

    const hideModal = () => this.setState({showModal: false, modalData: {}});

    return (
      <Layout route="tokens">
        <MetaTags>
          <title>API Config | RogoFire Portal</title>
        </MetaTags>
        <TokenOpModal show={this.state.showModal} modalData={this.state.modalData} hide={hideModal}
                      setModalData={(x) => this.setState({modalData: x})}
                      deleteTokenFn={this.props.deleteToken} getAllTokensFn={this.props.getAllTokens} />
        <Row>
          <Col md={12}>
            <div className="app-head">
              <div className="app-header">
                <h1>API Management</h1>
              </div>
              <div className="app-actions">
                <Link className="btn btn-outline-success btn-sm" to="/tokens/create">
                  <Icon name="plus" fixedWidth /> New Token
                </Link>
              </div>
            </div>

            <div className="box-table">
              <Table columns={getColumns(this)} data={tokens} />
            </div>
          </Col>
        </Row>
      </Layout>
    );
  };
};

const mapState = state => {
    const {
        result:tokens
    } = state.getAllTokens.toJS();

    return {
      tokens
    }
};

const mapDispatch = dispatch =>
    bindActionCreators(
        {
            ...actionCreators,
            getAllTokens: fetchDux.getAllTokens.createAction,
            deleteToken: fetchDux.deleteToken.createAction
        },
        dispatch
    );

export default connect(mapState, mapDispatch)(TokenList);
