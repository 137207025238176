import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '../../ui/Icon';

const required = value => value ? undefined : 'Required';
const checkStringLength = value => value.length <= 250 ? undefined : 'Maximum 250 characters';

const SendMessageForm = props => {
  const { handleSubmit, submitting, data, selectDevice, selectedDevices } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md={6} lg={4}>
          <label className="form-label">Devices</label>
          {(data || []).map(({device, metaData, lastKnown}) => {

            const {
              imei:deviceImei
            } = device || {}

            const{
              name
            } = metaData || {}

            const{
              typeReadable
            } = lastKnown || {}

            const selected = (selectedDevices || []).some((imei)=>{
              return(parseInt(deviceImei) === imei)
            });

            return(
              <a 
              className="form-block" 
              style={{backgroundColor: selected ? '#b3b3b3': 'white'}} 
              onClick={()=>selectDevice(parseInt(deviceImei))}
              >
                <strong>{name} {deviceImei}</strong>
                <small>{typeReadable}</small>
              </a>
            )
          }
          )}
        </Col>
        <Col md={6} lg={8}>
          <Field
            name="message"
            rows={6}
            component={FormField.Textarea}
            label="Message"
            validate={[required, checkStringLength]}
          />
        </Col>
      </Row>
      <div className="form-actions text-right">
        <Button
          variant="outline-success"
          type="submit"
          size="sm"
          disabled={submitting}
        >
          <Icon name="check" fixedWidth /> Send
        </Button>
        <Link className="btn btn-outline-secondary btn-sm" to={`/blocks`}>
          <Icon name="times" fixedWidth /> Cancel
        </Link>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'send-message'
})(SendMessageForm);
