import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ClientLayout from '../../ui/ClientLayout';
import Box from '../../ui/Box';
import Icon from '../../ui/Icon';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../state/modals-dux';
import MapComponent from '../../ui/MapComponent';
import Table from '../../ui/Table';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import download from 'downloadjs';
import { parse } from 'json2csv';

import fetchDux from '../../../state/fetch-dux';
import { actionCreators as orgActionCreators} from '../../../state/orgReducer';

const downloadCsv = (data, filename) => {
  const csvContent = parse( data );
  download(csvContent, filename, 'text/csv; charset=utf-8');
  return csvContent;
};

const tempFixForTemperatureBug = (temperature) => {
  if(temperature > 21474836){
    return (((temperature*100) + 65535) - 4294967295)/100
  }

  return temperature;
}

const convertTemp = (temperature, getCelsius=false, text=true, places=2) => {
  if(!temperature) return null;

  //temporary fix for temp values below freezing
  const tempFix = tempFixForTemperatureBug(temperature);

  if(getCelsius){
    const tempValue = (tempFix - 32)
    if(!tempValue) return (0).toFixed(places);

    if(text)return (tempValue / 1.8).toFixed(places);

    const temp = (tempValue / 1.8);

    return (Math.round((temp + Number.EPSILON) * 100) / 100);
    
  }
  if(text) return ((tempFix * 1.8) + 32).toFixed(places);
  const temp = ((tempFix * 1.8) + 32);
  return (Math.round((temp + Number.EPSILON) * 100) / 100);
  
}

const convertWind = (windSpeed, places=2) => {
  if(!windSpeed) return null;
  return ((windSpeed + Number.EPSILON) * 2.23694).toFixed(places);
  
}

const getCompassIndicator = (direction) => {
  if(!direction) return '-';
  switch (true){
    case (direction <= 11.25) : return 'N'
    case (direction >= 348.75): return 'N'
    case (direction > 11.25 && direction <= 33.75) : return 'NNE'
    case (direction > 33.75) && (direction <= 56.25) : return 'NE'
    case (direction > 56.25) && (direction <= 78.75) : return 'ENE'
    case (direction > 78.75) && (direction <= 101.25) : return 'E'
    case (direction > 101.25) && (direction <= 123.7) : return 'ESE'
    case (direction > 123.75) && (direction <= 146.25) : return 'SE'
    case (direction > 146.25) && (direction <= 168.75) : return 'SSE'
    case (direction > 168.75) && (direction <= 191.25) : return 'S'
    case (direction > 191.25) && (direction <= 213.75) : return 'SSW'
    case (direction > 213.75) && (direction <= 236.25) : return 'SW'
    case (direction > 236.25) && (direction <= 258.75) : return 'WSW'
    case (direction > 258.75) && (direction <= 281.25) : return 'W'
    case (direction > 281.25) && (direction <= 303.75) : return 'WNW'
    case (direction > 303.75) && (direction <= 326.25) : return 'NW'
    case (direction > 326.25) && (direction < 348.75) : return 'NNW'
    default:
      return '-';
  }
};

const getSymbol = (getCelsius=false) => {
  if(getCelsius){
    return <span>&#8451;</span>;
  }

  return (<span>&#8457;</span>);
   
 }

const columnsRaw = [
  {
    Header: 'Device',
    accessor: 'deviceMetaJoin.name.',
    className: 'text-center',
    Cell: ({original}) => { 
      const{
        deviceMetaJoin
      } = original || {};   

      const{
        name
      } = deviceMetaJoin || {};

    return (<span>{name}</span>)
    }
  },{
    Header: 'Transmit Time',
    accessor: 'transmitTime',
    className: 'text-center txTimeCol',
    headerClassName: 'txTimeCol',
    Cell: ({value}) => {
      return (<span>{moment(value).format('MM/DD/YYYY HH:mm')}</span>)
    }
  },
  {
    Header: 'Temperature',
    accessor: 'decoded.json.temperature',
    className: 'text-center',
    Cell: ({original}) => { 
      const{
        decoded
      } = original || {};   

      const{
        json
      } = decoded || {};

      const{
        temperature
      } = json || {};

      const getCelsius = false;
    return (<span>{convertTemp(temperature, getCelsius) || '-'} {getSymbol(getCelsius)}</span>)
    }
  },
  {
    Header: 'Humidity',
    accessor: 'decoded.json.humidity',
    className: 'text-center',
    Cell: ({original}) => { 
      const{
        decoded
      } = original || {};   

      const{
        json
      } = decoded || {};

      const{
        humidity
      } = json || {};

      return (<span>{ humidity ? (humidity).toFixed(2) : '-'}%</span>)
    }
  },
  {
    Header: 'Fine Dead Fuel',
    accessor: 'decoded.json.fineDeadFuelMoisture',
    className: 'text-center',
    Cell: ({original}) => { 
      const{
        decoded
      } = original || {};   

      const{
        json
      } = decoded || {};

      const{
        fineDeadFuelMoisture
      } = json || {};

    return (<span>{fineDeadFuelMoisture || '-'}%</span>)
    }
  },
  {
    Header: 'PIG',
    accessor: 'decoded.json.probabilityOfIgnition',
    className: 'text-center',
    Cell: ({original}) => { 
      const{
        decoded
      } = original || {};   

      const{
        json
      } = decoded || {};

      const{
        probabilityOfIgnition
      } = json || {};

    return (<span>{probabilityOfIgnition || '-'}%</span>)
    }
  },
  {
    Header: 'Wind Speed',
    accessor: 'decoded.json.windSpeed',
    className: 'text-center',
    Cell: ({ original }) => {
      const {
        decoded
      } = original || {};

      const {
        json
      } = decoded || {};

      const {
        windSpeed
      } = json || {};

      return (
          <span>
          {!isNaN(windSpeed || undefined) ? windSpeed.toFixed(1) : '-'} MPH
        </span>
      );
    }
  },
  {
    Header: 'Wind Direction (deg)',
    accessor: 'decoded.json.direction',
    className: 'text-center',
    Cell: ({ original }) => {
      const {
        decoded
      } = original || {};

      const {
        json
      } = decoded || {};

      const {
        direction
      } = json || {};

      return (
          <span>
          {!isNaN(direction || undefined) ? direction.toFixed(0) : '-'}°
        </span>
      );
    }
  },
  {
    Header: 'Wind Direction',
    accessor: 'decoded.json.direction',
    className: 'text-center',
    Cell: ({ original }) => {
      const {
        decoded
      } = original || {};

      const {
        json
      } = decoded || {};

      const {
        direction
      } = json || {};

      return (
          <span>
          {!isNaN(direction || undefined)
              ? getCompassIndicator(direction)
              : '-'}
        </span>
      );
    }
  },
  {
    Header: 'Heat Stress Index',
    accessor: 'decoded.json.heatStressIndex',
    show:false,
    className: 'text-center',
    Cell: ({original}) => { 
      const{
        decoded
      } = original || {};   

      const{
        json
      } = decoded || {};

      const{
        heatStressIndex
      } = json || {};

      const getCelsius = false;
      return (<span>{convertTemp(heatStressIndex, getCelsius) || '-'} {getSymbol(getCelsius)}</span>)
    }
  },
  {
    Header: 'Dew Point',
    accessor: 'decoded.json.dewPoint',
    className: 'text-center',
    show:false,
    Cell: ({original}) => { 
      const{
        decoded
      } = original || {};   

      const{
        json
      } = decoded || {};

      const{
        dewPoint
      } = json || {};

      const getCelsius = false;
      return (<span>{convertTemp(dewPoint, getCelsius) || '-'} {getSymbol(getCelsius)}</span>)
    }
  },
  {
    Header: 'Wet Bulb Temperature',
    accessor: 'decoded.json.wetBulbTemp',
    className: 'text-center',
    show:false,
    Cell: ({original}) => { 
      const{
        decoded
      } = original || {};   

      const{
        json
      } = decoded || {};

      const{
        wetBulbTemp
      } = json || {};

      const getCelsius = false;
      return (<span>{convertTemp(wetBulbTemp, getCelsius) || '-'} {getSymbol(getCelsius)}</span>)
    }
  },
];

const getCategoriesDays = (days, startDate, shouldSwitch)=>{
  const categories = [];
  for (let index = days; index >= 0; index--) {
    if((startDate && shouldSwitch)){
      categories.push(moment(startDate).subtract(index,'days').format('MMM-DD'))
    }else{
      categories.push(moment().subtract(index,'days').format('MMM-DD'))
    }
  }
  return categories;
}

const formatSeriesStructure= (categories, color)=>{

  return (categories || []).map((item)=>{
    return{
      name:item,
      data:((categories.length > 4) ? [0,0,0,0] : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]) ,
      color
    }
  })

}

const switchNames = (series)=>{
  const days = getCategoriesDays(14, null, null);
  (series || []).map((item, idx)=>{
    item.name = days[idx]
  })
}

const getSeries = (messages, seriesDays, seriesHours, startDate,  shouldSwitch)=>{

  const sortedMessages = (messages || []).sort(((a,b)=>{
    return new Date((a || {}).transmitTime) - new Date((b || {}).transmitTime)
  }));

  
  const daysCount = (seriesDays  || []).length; //make up for 0 index day series function
  
  const filteredMessages = (sortedMessages || []).filter((({transmitTime})=>{
    if(shouldSwitch){
      return (moment(transmitTime) > moment(startDate).hour(24).minutes(0).seconds(0).subtract(daysCount, 'days') &&
              moment(transmitTime) < moment(startDate).hour(24).minutes(0).seconds(0).add(1, 'days'))
    }else{
      return (moment(transmitTime) > moment().hour(24).minutes(0).seconds(0).subtract(daysCount, 'days'))
    }
  }));

  const tempSeriesByHour = formatSeriesStructure(seriesDays);
  const tempSeriesByDay = formatSeriesStructure(seriesHours, "#cc3300");
  const humSeriesByHour = formatSeriesStructure(seriesDays);
  const humSeriesByDay = formatSeriesStructure(seriesHours, "#0066cc");
  const fineDeadSeriesByHour = formatSeriesStructure(seriesDays);
  const fineDeadSeriesByDay = formatSeriesStructure(seriesHours, "#ff9900");
  const PIGSeriesByHour = formatSeriesStructure(seriesDays);
  const PIGSeriesByDay = formatSeriesStructure(seriesHours, "#cc3300");

  (filteredMessages || []).map(({decoded, transmitTime})=>{

    const{
      json
    } = decoded || {};

    const{
      temperature,
      humidity,
      fineDeadFuelMoisture,
      probabilityOfIgnition
    } = json || {};

    //get hour string
    const hourString = shouldSwitch ? moment(transmitTime).subtract(1, 'hour').format('HH00') : moment(transmitTime).format('HH00');

    let bucketHourIndex = -1;
    const bucketDayIndex = shouldSwitch ? 
    14 - (moment(startDate).diff(moment(transmitTime), 'days', false) + 1)
    : 14 - (moment(new Date()).diff(moment(transmitTime), 'days', false));

    switch (hourString) {
      case '0800':
      case '0900':
        bucketHourIndex = 0
        break;
      case '1000':
      case '1100':
        bucketHourIndex = 1
        break;
      case '1200':
      case '1300':
        bucketHourIndex = 2
        break;
      case '1400':
      case '1500':
        bucketHourIndex = 3
        break;
      default:
        break;
    }

    //get day string
    const dayString = moment(transmitTime).format('MMM-DD');

    //get array indices
    const temperatureHourIndex = tempSeriesByHour.findIndex(x => x.name === dayString);
    const humidityHourIndex = humSeriesByHour.findIndex(x => x.name === dayString);
    const temperatureDayIndex = tempSeriesByDay.findIndex(x => x.name === hourString);
    const humidityDayIndex = humSeriesByDay.findIndex(x => x.name === hourString);

    const fdmHourIndex = fineDeadSeriesByHour.findIndex(x => x.name === dayString);
    const pigHourIndex = PIGSeriesByHour.findIndex(x => x.name === dayString);
    const fdmDayIndex = fineDeadSeriesByDay.findIndex(x => x.name === hourString);
    const pigDayIndex = PIGSeriesByDay.findIndex(x => x.name === hourString);

    //push to series array
    if(temperatureHourIndex >= 0 ) ((tempSeriesByHour[temperatureHourIndex] || {}).data || [])[bucketHourIndex] = convertTemp(temperature, false, false);
    if(humidityHourIndex >= 0) ((humSeriesByHour[humidityHourIndex] || {}).data || [])[bucketHourIndex] = humidity;
    if(temperatureDayIndex >= 0) ((tempSeriesByDay[temperatureDayIndex] || {}).data || [])[bucketDayIndex] = convertTemp(temperature, false, false);
    if(humidityDayIndex >= 0) ((humSeriesByDay[humidityDayIndex] || {}).data || [])[bucketDayIndex] = humidity;

    if(fdmHourIndex >= 0) ((fineDeadSeriesByHour[fdmHourIndex] || {}).data || [])[bucketHourIndex] = fineDeadFuelMoisture;
    if(pigHourIndex >= 0) ((PIGSeriesByHour[pigHourIndex] || {}).data || [])[bucketHourIndex] = probabilityOfIgnition;
    if(fdmDayIndex >= 0) ((fineDeadSeriesByDay[fdmDayIndex] || {}).data || [])[bucketDayIndex] = fineDeadFuelMoisture;
    if(pigDayIndex >= 0) ((PIGSeriesByDay[pigDayIndex] || {}).data || [])[bucketDayIndex] = probabilityOfIgnition;

  })

  
  if(shouldSwitch){
    switchNames(tempSeriesByHour)
    switchNames(humSeriesByHour)
    switchNames(fineDeadSeriesByHour)
    switchNames(PIGSeriesByHour)
  }

  return ([
    tempSeriesByHour,
    humSeriesByHour,
    tempSeriesByDay,
    humSeriesByDay,
    fineDeadSeriesByHour,
    PIGSeriesByHour,
    fineDeadSeriesByDay,
    PIGSeriesByDay
  ])
}

const SWITCH_STAGED_DATA = false;
const SWITCH_START_DATE = '10/02/2020';

export class ClientPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataTrendsShow: true,
      fireTrendsShow: true,
      currentDataShow: true,
      rawDataShow: true,
      sortOptions: [{id:'transmitTime', desc:true}]
    }
  }

  resize() {
    this.setState({hideDesktop: window.innerWidth <= 760});
  } 

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
    this.props.clearGetDeviceDetail();
  }

  componentDidMount() {
    const {
      match,
      orgId
    } = this.props || {}
    const {
      params
    } =match || {};
    const{
      id:selectedId
    } = params || {};

    this.setState({hideDesktop: window.innerWidth <= 760});
    window.addEventListener("resize", this.resize.bind(this));
    if(orgId){
      if(SWITCH_STAGED_DATA && (orgId === '5faeef1a76c6f86b2583494e' || orgId === '5f0e025e551ea871a766f7e6')){
        this.props.getDeviceDetailStaged({selectedId:'5f0e05e28d1309748cd842e1', orgId:'5f24453b4f00e93a35e190bb'});
      }
      this.props.getDeviceDetail({selectedId, orgId});
      this.props.getDevicesByOrgId(orgId);
    }
  }

  componentWillReceiveProps(nextProps) {
    const newOrg = nextProps.orgId;

    const {
      match,
      orgId:oldOrg
    } = this.props || {}
    const {
      params
    } =match || {};
    const{
      id:selectedId
    } = params || {};

    if (oldOrg === newOrg) return;
    this.props.getDevicesByOrgId(newOrg);
    this.props.getDeviceDetail({selectedId, orgId:newOrg});
  }

  render() {

    const { 
      devices, 
      deviceDetails,
      orgId,
      match,
      inProgress,
      user,
      getDeviceCsv,
      history,
      deviceDetailsError,
      deviceDetailsStaged
    } = this.props || {};

    const {
      hideDesktop
    } = this.state || {}

    const {
      params
    } =match || {};

    const{
      id:selectedId
    } = params || {};

    const currentDevice = (devices || []).find(({deviceId})=>(deviceId === selectedId))

    if(deviceDetailsError && !inProgress) {
      setTimeout(function () {
        history.push('/blocks');
      }, 2500);
    }

    const{
      readableOrg,
      isDeleted,
      lastKnown,
      _id:id,
      device,
      metaData,
      deviceId
    } = currentDevice || {};

    const {
      imei
    } = device || {}
    const {
      name
    } = metaData || {}

    const{
      name: orgName,
      slug: orgSlug
    } = readableOrg || {};

    const {
      messages,
      locations,
      lastKnown:lastDetail
    } = deviceDetails || {}

    const {
      messages: stagedMessages,
    } = deviceDetailsStaged || {}

    const {
      lastTransmit,
      temperature: dryBulb,
      wetBulbTemp,
      dewPoint,
      humidity: relativeHumidity,
      fineDeadFuelMoisture,
      probabilityOfIgnition,
      direction,
      windSpeed
    } = lastDetail || {}

    const{
      dataTrendsShow,
      fireTrendsShow,
      currentDataShow,
      rawDataShow
    } = this.state || { }

    const categoriesHours = ["0900", "1100", "1300", "1500"];
    
    const shouldSwitch =  ((orgId === '5faeef1a76c6f86b2583494e' || orgId === '5f0e025e551ea871a766f7e6') && SWITCH_STAGED_DATA)
    const categoriesDays = getCategoriesDays(14, SWITCH_START_DATE, shouldSwitch);
    const categoriesDaysNoSwap = getCategoriesDays(14, null, null);

    const messagesToUse = (SWITCH_STAGED_DATA && stagedMessages) ? stagedMessages : messages;

    const [
      tempByHour,
      humSeriesByHour,
      tempByDay,
      humByDay,
      fineDeadByHour,
      PIGsByHour,
      fineDeadByDay,
      PIGByDay
    ] = getSeries(messagesToUse, categoriesDays, categoriesHours, SWITCH_START_DATE, shouldSwitch);


    const locationHistory = {[deviceId]: (messagesToUse || []).map(msg => {
      return {
        latitude: msg.decoded.json.latitude,
        longitude: msg.decoded.json.longitude,
        timestamp: msg.transmitTime
      }
    })};

    const graphTempHourOptions = {
      chart: {
        type: 'column',
        height:'35%',
      },
      title: {
        text: 'Temperatures'
      },
      xAxis: {
        categories: categoriesHours,
        title:'By Hour trends'
      },
      yAxis: [{ // Primary yAxis
        labels: {
            format: '{value}°F',
        },
        title: {
            text: 'Temperature',

        },
        opposite: false,
        min:0,
        max:110 
      }],
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        borderWidth: 0
      },
      series: tempByHour
    };
    const graphHumidityHourOptions = {
      chart: {
        type: 'column',
        height:'35%',
      },
      title: {
        text: 'Relative Humidity'
      },
      xAxis: {
        categories: categoriesHours,
        title:'By Hour trends'
      },
      yAxis: [{ // Primary yAxis
        labels: {
            format: '{value} %',
        },
        title: {
            text: '% Humidity',

        },
        opposite: false,
        min:0,
        max:100 
      }],
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        borderWidth: 0
      },
      series: humSeriesByHour
    };
    const graphTempDayOptions = {
      chart: {
        type: 'column',
        height:'35%',
      },
      title: {
        text: 'Temperatures'
      },
      xAxis: {
        categories: (shouldSwitch ? categoriesDaysNoSwap : categoriesDays),
        title:'By Day Trends'
      },
      yAxis: [{ // Primary yAxis
        labels: {
            format: '{value}°F',
        },
        title: {
            text: 'Temperature',

        },
        opposite: false,
        min:0,
        max:110 
      }],
      legend: {
        enabled: false
      },
      series: tempByDay
    };
    const graphHumidityDayOptions = { 
      chart: {
        type: 'column',
        height:'35%',
      },
      title: {
        text: 'Relative Humidity'
      },
      xAxis: {
        categories: (shouldSwitch ? categoriesDaysNoSwap : categoriesDays),
        title:'By Day Trends'
      },
      yAxis: [{ // Primary yAxis
        labels: {
            format: '{value} %',
        },
        title: {
            text: '% Humidity',

        },
        opposite: false,
        min:0,
        max:100 
      }],
      legend: {
        enabled: false
      },
      series: humByDay
    };
    const graphFDMHourOptions = {
      chart: {
        type: 'column',
        height:'35%',
      },
      title: {
        text: 'Fine Dead Fuel Moisture'
      },
      xAxis: {
        categories: categoriesHours,
        title:'By Hour trends'
      },
      yAxis: [{ // Primary yAxis
        labels: {
            format: '{value} %',
        },
        title: {
            text: 'Moisture Percent',

        },
        opposite: false,
        min:0,
        max:25 
      }],
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        borderWidth: 0
      },
      series: fineDeadByHour
    };
    const graphPIGHourOptions = {
      chart: {
        type: 'column',
        height:'35%',
      },
      title: {
        text: 'Probability of Ignition'
      },
      xAxis: {
        categories: categoriesHours,
        title:'By Hour trends'
      },
      yAxis: [{ // Primary yAxis
        labels: {
            format: '{value} %',
        },
        title: {
            text: 'Probability (%)',

        },
        opposite: false,
        min:0,
        max:100 
      }],
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        borderWidth: 0
      },
      series: PIGsByHour
    };
    const graphFDMDayOptions = {
      chart: {
        type: 'column',
        height:'35%',
      },
      title: {
        text: 'Fine Dead Fuel Moisture'
      },
      xAxis: {
        categories: (shouldSwitch ? categoriesDaysNoSwap : categoriesDays),
        title:'By Day Trends'
      },
      yAxis: [{ // Primary yAxis
        labels: {
            format: '{value} %',
        },
        title: {
            text: 'Moisture Percent',

        },
        opposite: false,
        min:0,
        max:25 
      }],
      legend: {
        enabled: false
      },
      series: fineDeadByDay
    };
    const graphPIGDayOptions = { 
      chart: {
        type: 'column',
        height:'35%',
      },
      title: {
        text: 'Probability of Ignition'
      },
      xAxis: {
        categories: (shouldSwitch ? categoriesDaysNoSwap : categoriesDays),
        title:'By Day Trends'
      },
      yAxis: [{ // Primary yAxis
        labels: {
            format: '{value} %',
        },
        title: {
            text: 'Probability (%)',

        },
        opposite: false,
        min:0,
        max:100 
      }],
      legend: {
        enabled: false
      },
      series: PIGByDay
    };

    const handleExportAll = () => {
      const data = (messages || []).map((message)=>{

        const{
          decoded,
            transmitTime
        } = message || {};   
  
        const{
          json
        } = decoded || {};

        const {
          dewPoint,
          windSpeed,
          direction,
          fineDeadFuelMoisture,
          heatStressIndex,
          humidity,
          probabilityOfIgnition,
          stationPressure,
          temperature,
          wetBulbTemp
        } = json || {};

        return Object.assign(
          {},
          { 
            Device:name,
            TransmitTime:transmitTime,
            DryBulbTemperature:convertTemp(temperature, false, false),
            RelativeHumidity:humidity,
            WindSpeed:windSpeed,
            WindDirectionDeg:direction,
            WindDirection:getCompassIndicator(direction),
            FDFM:fineDeadFuelMoisture,
            PIG:probabilityOfIgnition,
            HeatStressIndex:convertTemp(heatStressIndex,false,false),
            DewPoint:convertTemp(dewPoint,false,false),
            WetBulbTemp:convertTemp(wetBulbTemp,false,false),
            StationPressure:stationPressure
          }
        );

      });
    
      downloadCsv(data, `export_csv_${name}.csv`);
    };

    return (
      <ClientLayout route="blocks-detail">
        <MetaTags>
          <title>Data Insights | RogoFire Portal</title>
        </MetaTags>
        <Row>
          <Col md={12}>

            <div className="app-head">

              <div className="app-header">

                <h1>Data Insights</h1>

                <Link className="btn-back" to="/blocks">
                  <Icon name="arrow-left" fixedWidth /> Device Portal
                </Link>

              </div>
            </div>
          </Col>
          
          {(!currentDevice || deviceDetailsError) &&
            !inProgress &&
              <Col md={12}>
                <Col md={6}>
                  <div className="alert alert-primary  alert-dismissible">
                    <strong>Info:</strong> device not found for selected organization, redirecting back to devices...
                  </div>
                  {/* <Link className="btn btn-outline-secondary btn-sm" to={`/blocks`}>
                    Go Back to Devices
                  </Link> */}
                </Col>
              </Col>
          }
          {currentDevice &&
            <Col md={12}>
              {!hideDesktop && 
                <Row>
                  <Col md={12}>
                    <div className="box-map">
                      <MapComponent 
                        devices={[currentDevice] || []} 
                        containerHeight={470} 
                        onlyOne
                        locationHistory={locationHistory}
                        enableHistory={(currentDevice || {}).device.breadcrumbEnable}
                      />
                    </div>
                  </Col>
                </Row>
              }
              <Row>
                <Col md={12}>
                  <div className="box-tabs">
                    <Tabs defaultActiveKey="dataDash">
                      <Tab eventKey="dataDash" title="Current Data" style={{padding:'15px'}}>
                        <div>
                          {!hideDesktop && 
                            <Row >
                              <Col md={12}>
                                {currentDataShow &&
                                  <div 
                                    className="alert alert-primary alert-dismissible fade show " 
                                    role="alert" 
                                  >
                                    <Icon name="info-circle" fixedWidth />
                                    &nbsp;Times displayed below are for standard time and do not adjust for DST.
                                    <button type="button" className="close" onClick={()=>this.setState({ currentDataShow: false })}>
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>
                                }
                              </Col>
                              <Col  md={12}>
                                <Box style={{padding:"15px"}}>
                                  <h5>
                                    <strong>
                                      Current Conditions
                                    </strong>
                                    <span>
                                      <span> as of {lastTransmit ? moment(lastTransmit).format('MM/DD/YYYY HH:mm') : 'Unknown'}</span>
                                    </span>
                                  </h5>
                                  <br/>
                                  <p>
                                    At {lastTransmit ? moment(lastTransmit).format('HHmm') : '-'} hours the Dry Bulb temperature is {convertTemp(dryBulb, false, true, 0) || '-'} {getSymbol(false)}, 
                                    the Wet Bulb temperature is {convertTemp(wetBulbTemp, false, true, 0) || '-'} {getSymbol(false)} and the Dew Point is {convertTemp(dewPoint, false, true, 0) || '-'} {getSymbol(false)}. 
                                    Relative Humidity is { relativeHumidity ? (relativeHumidity).toFixed(0) : '-'}%. 
                                    Fine Dead Fuel Moisture is {fineDeadFuelMoisture}% and the Pig is {probabilityOfIgnition}%. 
                                  </p>
                                </Box>
                              </Col>
                            </Row>
                          }
                          {hideDesktop && 
                            <Row >
                              <Col  md={12}>
                                <h5>
                                  <strong>
                                    Current Conditions
                                  </strong>
                                  <span>
                                    <span> as of {lastTransmit ? moment(lastTransmit).format('MM/DD/YYYY HH:mm') : 'Unknown'}</span>
                                  </span>
                                </h5>
                                <br/>
                              </Col>
                            </Row>
                          }
                          {(direction || windSpeed) &&
                            <Row>
                              <Col  sm={6}>
                                <Box style={{padding:"15px"}}>
                                  <h6>
                                    <strong>
                                      Direction
                                    </strong>
                                  </h6>
                                  <p style={{fontSize:'300%', textAlign:'center', fontWeight:'bold'}}>
                                    {getCompassIndicator(direction)} {!!direction || direction === 0 ? (direction).toFixed(0) : '-'}<span>&#176;</span>
                                  </p>
                                </Box>
                              </Col>
                              <Col  sm={6}>
                                <Box style={{padding:"15px"}}>
                                  <h6>
                                    <strong>
                                      Wind Speed
                                    </strong>
                                  </h6>
                                  <p style={{fontSize:'300%', textAlign:'center', fontWeight:'bold'}}>
                                    {convertWind(windSpeed) || '-'} Mph
                                  </p>
                                </Box>
                              </Col>
                            </Row>
                          }
                          <Row>
                            <Col  sm={6}>
                              <Box style={{padding:"15px"}}>
                                <h6>
                                  <strong>
                                    Dry Bulb Temperature
                                  </strong>
                                </h6>
                                <p style={{fontSize:'300%', textAlign:'center', fontWeight:'bold'}}>
                                  {convertTemp(dryBulb, false, true, 0) || '-'} {getSymbol(false)}
                                </p>
                              </Box>
                            </Col>
                            <Col  sm={6}>
                              <Box style={{padding:"15px"}}>
                                <h6>
                                  <strong>
                                    Relative Humidity
                                  </strong>
                                </h6>
                                <p style={{fontSize:'300%', textAlign:'center', fontWeight:'bold'}}>
                                  {relativeHumidity ? (relativeHumidity).toFixed(0) : '-'}%
                                </p>
                              </Box>
                            </Col>
                          </Row>
                          <Row>
                            <Col  sm={6}>
                              <Box style={{padding:"15px"}}>
                                <h6>
                                  <strong>
                                    Fine Dead Fuel Moisture
                                  </strong>
                                </h6>
                                <p style={{fontSize:'300%', textAlign:'center', fontWeight:'bold'}}>
                                  {fineDeadFuelMoisture}%
                                </p>
                              </Box>
                            </Col>
                            <Col  sm={6}>
                              <Box style={{padding:"15px"}}>
                                <h6>
                                  <strong>
                                    Probability of Ignition
                                  </strong>
                                </h6>
                                <p style={{fontSize:'300%', textAlign:'center', fontWeight:'bold'}}>
                                  {probabilityOfIgnition}%
                                </p>
                              </Box>
                            </Col>
                          </Row>
                        </div>
                      </Tab>
                      <Tab eventKey="fireTrends" title="Fire Metrics/Trends" style={{padding:'15px'}}>
                        <Box style={{padding:"15px"}}>
                          {fireTrendsShow &&
                            <div 
                              className="alert alert-primary alert-dismissible fade show " 
                              role="alert" 
                            >
                              <Icon name="info-circle" fixedWidth />
                              &nbsp;Times displayed below are for standard time and do not adjust for DST.
                              <button type="button" className="close" onClick={()=>this.setState({ fireTrendsShow: false })}>
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                          }
                          <h4>
                            <strong>
                              BY DAY TRENDS
                            </strong>
                          </h4>
                          <HighchartsReact highcharts={Highcharts} options={graphFDMDayOptions} />
                          <br/>
                          <HighchartsReact highcharts={Highcharts} options={graphPIGDayOptions} />  
                        </Box>
                        <Box style={{padding:"15px"}}>
                            <h4>
                              <strong>
                                BY HOUR TRENDS
                              </strong>
                            </h4>
                          <HighchartsReact highcharts={Highcharts} options={graphFDMHourOptions} />
                          <br/>
                          <HighchartsReact highcharts={Highcharts} options={graphPIGHourOptions} />  
                        </Box>
                      </Tab>
                      <Tab eventKey="dataTrends" title="Data Trends" style={{padding:'15px'}}>
                        <Box style={{padding:"15px"}}>
                          {dataTrendsShow &&
                            <div 
                              className="alert alert-primary alert-dismissible fade show " 
                              role="alert" 
                            >
                              <Icon name="info-circle" fixedWidth />
                              &nbsp;Times displayed below are for standard time and do not adjust for DST.
                              <button type="button" className="close" onClick={()=>this.setState({ dataTrendsShow: false })}>
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                          }
                          <h4>
                            <strong>
                              BY DAY TRENDS
                            </strong>
                          </h4>
                          <HighchartsReact highcharts={Highcharts} options={graphTempDayOptions} />
                          <br/>
                          <HighchartsReact highcharts={Highcharts} options={graphHumidityDayOptions} />  
                        </Box>
                        <Box style={{padding:"15px"}}>
                          <h4>
                            <strong>
                              BY HOUR TRENDS
                            </strong>
                          </h4>
                          <HighchartsReact highcharts={Highcharts} options={graphTempHourOptions} />
                          <br/>
                          <HighchartsReact highcharts={Highcharts} options={graphHumidityHourOptions} />  
                        </Box>
                      </Tab>
                      <Tab eventKey="rawData" title="Raw Data" style={{padding:'15px'}}>
                        {rawDataShow &&
                          <div 
                            className="alert alert-primary alert-dismissible fade show " 
                            role="alert" 
                          >
                            <Icon name="info-circle" fixedWidth />
                            &nbsp;Times displayed below are for standard time and do not adjust for DST.
                            <button type="button" className="close" onClick={()=>this.setState({ rawDataShow: false })}>
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        }
                        <Table
                          id='dataChart'
                          showExport
                          showColumnsSelector
                          columns={columnsRaw}
                          data={messages}
                          defaultPageSize={20}
                          onExportAll={handleExportAll}
                          sorted={this.state.sortOptions}
                          onSortedChange={val => {
                            this.setState({ sortOptions: val }) }}
                        />
                      </Tab>
                    </Tabs>
                  </div>
                </Col>
              </Row>
            </Col>
          }
        </Row>
      </ClientLayout>
    );
  };
};

const mapState = state => {

  const {
    result:devices,
    inProgress
  } = state.getDevicesByOrgId.toJS();

  const {
    result:deviceDetails,
    error:deviceDetailsError
  } = state.getDeviceDetail.toJS();

  const {
    result:deviceDetailsStaged,
    error:deviceDetailsErrorStaged
  } = state.getDeviceDetailStaged.toJS();

  const {
    inProgress:deleteInProgress,
    error:deleteError,
  } = state.deleteDevice.toJS();

  const {orgId} = state.organizations || {};

  const user = (state.login.toJS() || {}).result 

  return {
    orgId,
    devices,
    deviceDetails,
    inProgress,
    deleteError,
    deleteInProgress,
    user,
    deviceDetailsError,
    deviceDetailsStaged,
    deviceDetailsErrorStaged
  }
};

const mapDispatch = dispatch => bindActionCreators(
  {
    ...orgActionCreators,
    ...actionCreators,
    getDevicesByOrgId: fetchDux.getDevicesByOrgId.createAction,
    getDeviceDetail: fetchDux.getDeviceDetail.createAction,
    getDeviceDetailStaged: fetchDux.getDeviceDetailStaged.createAction,
    clearGetDeviceDetail: fetchDux.getDeviceDetail.clearAction,
    deleteDevice: fetchDux.deleteDevice.createAction,
    clearDeleteDevice: fetchDux.deleteDevice.clearAction
  }, dispatch);
export default connect(mapState, mapDispatch)(ClientPortal);
