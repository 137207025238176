import React, { Component, createElement } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const renderComponent = ({ render, component, validateLoginResult, isAdminRoute }) => props => {
  const {
    result,
    error
  } = validateLoginResult || {};

  if (error) return <Redirect to="/login" />

  const{
    isAdmin
  } = result || {};

  const hasAccess = isAdminRoute ? isAdmin : true;

  if (result && hasAccess) return render ? render(props) : createElement(component, props);

  if (!hasAccess && result) return <Redirect to="/" />

  return <div></div>;
}

class SecureRoute extends Component {
  render() {
    const { component, render, validateLoginResult, permissions, isAdminRoute, ...rest } = this.props;
    return <Route
      {...rest}
      render={renderComponent({ render, component, validateLoginResult, isAdminRoute })}
    />
  }
}

const mapState = state => ({ validateLoginResult: state.validateLogin.toJS() });

export default connect(mapState)(SecureRoute);