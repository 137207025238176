import React from 'react';
import { Route } from 'react-router';
import { Nav } from 'react-bootstrap';

export default (
  {
    to,
    exact,
    strict,
    isActive,
    ...rest
  }
) => (
  <Route
    path={typeof to === 'object' ? to.pathname : to}
    exact={exact}
    strict={strict}
    children={({ location, match, history }) => {
      const active = !!(isActive
        ? isActive(match, location)
        : match && match.isExact);
      return (
        <Nav.Link
          {...rest}
          href={to}
          onClick={e => {
            e.preventDefault();
            history.push(to);
          }}
          active={active}
        />
      );
    }}
  />
);
